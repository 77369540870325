import {
    ReportState,
    LOAD_REPORTS,
    LOAD_REPORTS_SUCCESS,
    SORT_REPORTS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_STATE,
    AdminReportsActionTypes,
  } from "./types";
  import { sortByField } from "../../lib/sort";

  const initialSort = {
    field: 'timestamp',
    direction: false
}

  const initialState: ReportState = {
    loading: false,
    reportSortOptions: initialSort,
    report: '',
    filterByDate: { to: new Date(new Date(Date.now()).toISOString().split('T')[0]), from: new Date(new Date(Date.now()).toISOString().split('T')[0]) },
    filterByDateType: { label: 'Last 7 days', value: 'Last 7 days' }
  };

  export function reportReducer(
    state = initialState,
    action: AdminReportsActionTypes
  ): ReportState {
    switch (action.type) {
      case LOAD_REPORTS_SUCCESS:
        return {
          ...state,
          loading: false,
          report: action.payload
      };

      case LOAD_REPORTS:
        return {
          ...state,
          report: action.payload,
          loading: true,
        };

      case SORT_REPORTS:
        state.reportSortOptions.direction = !state.reportSortOptions.direction;
        state.reportSortOptions.field = action.payload.fieldValue;
        return {
            ...state,
            reportSortOptions: Object.assign({}, state.reportSortOptions)
        };

      case FILTER_DATE:
        console.log(action);
        console.log(state);
        return {
          ...state,
          filterByDate: action.payload
        };

      case FILTER_DATE_TYPE:
        return {
          ...state,
          filterByDateType: action.payload
        };

        case RESET_STATE:
          return {...initialState}

    default:
      return state;
    }
  }
