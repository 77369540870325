import axios from 'axios';
import promise from 'promise';
import { Session } from '../store/auth/types';
import * as config from '../config/app-config.json';
import cognitoUtils from './cognitoUtils';
import { store } from '../store';
import moment from 'moment';


axios.interceptors.request.use(async (config) => {
    console.log(config);
    //config.url += `?t=${moment().format('X')}`;
    if (config.method !== 'OPTIONS') {
        let session = localStorage.getItem('session');
        let decodedSession: Session = session === null ? session : JSON.parse(session);
        let state = store.getState();
        if(state.system.selectedClient !== null) {
            config.headers!['X-On-Behalf'] = state.system.selectedClient.clientEmail
        }
        if(session !== null) {
            // ...Authorization produced error: Property 'Authorization' does not exist on type 'AxiosHeaders | Partial<Record<string,
            // AxiosHeaderValue> & MethodsHeaders & CommonHeaders>'. Property 'Authorization' does not exist on type 'AxiosHeaders'.
            // config.headers.Authorization = `Bearer ${decodedSession.credentials.idToken}`
            config.headers!['Authorization'] = `Bearer ${decodedSession.credentials.idToken}`
            config.headers!['Content-Type'] = 'application/json';
        } else {
            document.location.href = cognitoUtils.getCognitoSignInUri();
        }

        return config;
    }
    return config;

}, (error) => {
   return promise.reject(error);
});

axios.interceptors.response.use(undefined, (error) => {
    console.log(error);
   if(error.response && error.response.status === 401) {
        let session = localStorage.getItem('session');
        let decodedSession: Session = session === null ? session : JSON.parse(session);
        console.log(decodedSession);
        fetch(`https://cognito-idp.${config.region}.amazonaws.com/`, {
            headers: {
                "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
                "Content-Type": "application/x-amz-json-1.1",
            },
            mode: 'cors',
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                ClientId: `${config.clientId}`,
                AuthFlow: 'REFRESH_TOKEN_AUTH',
                AuthParameters: {
                    REFRESH_TOKEN: (decodedSession === null) ? '' : decodedSession.credentials.refreshToken
                }
            }),
        }).then((res) => {
            console.log(res);
            return res.json();
        }).then(data => {
            if(decodedSession === null) {
                document.location.href = cognitoUtils.getCognitoSignInUri();
            } else {
                if(!data.AuthenticationResult) {
                    localStorage.removeItem('session');
                    document.location.href = cognitoUtils.getCognitoSignInUri();
                } else {
                    decodedSession.credentials.idToken = data.AuthenticationResult.IdToken;
                    localStorage.setItem('session', JSON.stringify(decodedSession) );
                    document.location.reload();
                }

            }


        }, () => {
            document.location.href = cognitoUtils.getCognitoSignInUri();
        });
   }
   return promise.reject(error);
});

export default axios;
