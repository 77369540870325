import {
    ClientsState,
    LOAD_CLIENTS,
    LOAD_CLIENTS_SUCCESS,
    UPDATE_CLIENT_FIELD,
    LOAD_CLIENT_BY_ID,
    LOAD_CLIENT_BY_ID_SUCCESS,
    CREATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_SUCCESS,
    REMOVE_CLIENT_BY_ID,
    REMOVE_CLIENT_BY_ID_SUCCESS,
    SORT_CLIENTS,
    ClientActionTypes,
    FILTER_CLIENTS
} from './types';

import { sortByField } from "../../lib/sort";

const emptyClient: any = {
    clientName: '',
    clientEmail: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    bankName: '',
    routingNumber: '',
    accountNumber: '',
    facebook: false
}

const initialSort = {
    field: 'end',
    direction: false
}

const initialState: ClientsState = {
    loading: false,
    loadingClient: false,
    clients: [],
    clientsFiltered: [],
    clientsSortOptions: Object.assign({}, initialSort),
    editedClient: Object.assign({}, emptyClient),
};

export function clientsReducer(
    state = initialState,
    action: ClientActionTypes
): ClientsState {
    switch (action.type) {
        case LOAD_CLIENTS:
            return {
                ...state,
                clients: [],
                loading: true
            }
        case LOAD_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: sortByField(state.clientsSortOptions.field, state.clientsSortOptions.direction, action.payload),
                loading: false
            };

        case LOAD_CLIENT_BY_ID:
            return {
                ...state,
                loadingClient: true
            };

        case LOAD_CLIENT_BY_ID_SUCCESS:
            return {
                ...state,
                editedClient: action.payload,
                loadingClient: false
            };

        case CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                editedClient: Object.assign({}, emptyClient)
            };

        case UPDATE_CLIENT_SUCCESS:
            return {
                ...state
            };

        case UPDATE_CLIENT_FIELD:
            let editedClient : any = Object.assign({}, state.editedClient);
            editedClient[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedClient
            }

        case SORT_CLIENTS:
            state.clientsSortOptions.direction = !state.clientsSortOptions.direction;
            state.clientsSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                clients: sortByField(state.clientsSortOptions.field, state.clientsSortOptions.direction, state.clients),
                clientsSortOptions: Object.assign({}, state.clientsSortOptions)
            }

        case FILTER_CLIENTS:
                let clientsFiltered: Array<any> = [];
      
                state.clients.map(account => {
                  if(account.clientName.toLowerCase().indexOf(action.payload.toLowerCase()) > -1) {
                    clientsFiltered.push(account);
                  }
                })
      
                return {
                  ...state,
                  clientsFiltered: clientsFiltered
                }

        default:
            return state;
    }
}
