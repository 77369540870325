import { LOAD_DESTINATIONS_SUCCESS, UPDATE_DESTINATION_FIELD, LOAD_DESTINATION_BY_ID, LOAD_DESTINATION_BY_ID_SUCCESS, LOAD_DESTINATIONS, UPDATE_DESTINATION_SUCCESS, CREATE_DESTINATION_SUCCESS, Destination, REMOVE_DESTINATION_BY_ID, REMOVE_DESTINATION_BY_ID_SUCCESS, SORT_DESTINATIONS, DELETE_DESTINATION_SUCCESS, DELETE_DESTINATION, TEST_DESTINATION, TEST_DESTINATION_SUCCESS } from "./types";
import store from '../../store';
import axios from '../../lib/axios';
import axiosOld from 'axios';
import config from '../../config/app-config.json';

export const emptyDestination = () => {
    return {
        type: CREATE_DESTINATION_SUCCESS,
        payload: null
    }
}

export const deleteDestination = id => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_DESTINATION,
            payload: null
        })
        axios.delete(`${config.serviceUrl}/destination/${id}`).then((response) => {
            dispatch({
                type: DELETE_DESTINATION_SUCCESS,
                payload: response.data
            })
        })
    }
}

function macroFill(url, macros) {
    //macros are email, timestamp, custom1-10
    var newUrl = url;
    Object.keys(macros).forEach(function (item) {
        console.log(item); // key
        console.log(macros[item]); // value
        newUrl = newUrl.replaceAll(`{{${item}}}`, macros[item])
    });
    return newUrl;
}

function macroBodyFill(body, macros) {
    var newBody = body;
    Object.keys(body).forEach(function (item) {
        console.log(item); // key
        console.log(body[item]); // value
        if (body[item] == `{{${item}}}`) {
            newBody[item] = macros[item];
        }
    });
    return newBody;
}

export const testDestination = destination => {
    return (dispatch: any) => {
        try {
            dispatch({
                type: TEST_DESTINATION,
                payload: null
            })
            console.log(destination);

            const scriptParams = {
                custom1: "Testing1Custom",
                custom2: "Testing 2 Custom",
                custom3: undefined,
                custom4: "Testing missing custom",
                custom5: undefined,
                custom6: undefined,
                custom7: undefined,
                custom8: undefined,
                custom9: undefined,
                custom10: undefined,
                zid: 1234,
                pid: 123456,
            };
            const email = "Testing@smartrecognition.com";
            const timestamp = "2023-11-14T15:18:38.000Z";
            const source = 123;

            console.log(scriptParams);
            const macros = {
                email: email,
                hashEmail: email,
                timestamp: timestamp,
                sourceId: source,
                custom1: scriptParams.custom1,
                custom2: scriptParams.custom2,
                custom3: scriptParams.custom3,
                custom4: scriptParams.custom4,
                custom5: scriptParams.custom5,
                custom6: scriptParams.custom6,
                custom7: scriptParams.custom7,
                custom8: scriptParams.custom8,
                custom9: scriptParams.custom9,
                custom10: scriptParams.custom10,
                zid: scriptParams.zid,
                pid: scriptParams.pid,
            }

            console.log(macros);
            console.log('headers', destination.headers);

            let reqConfig: any = {
                method: destination.method,
                url: macroFill(destination.url, macros),
                headers: { 'Content-Type': 'application/json' }
            };

            if (destination.method == 'POST') {
                reqConfig.body = macroBodyFill(destination.body, macros);
                console.log('reqConfig:');
                console.log(reqConfig);
                axiosOld.post(macroFill(destination.url, macros), macroBodyFill(destination.body, macros), {headers: {'Content-Type': 'application/json'}}).then((response) => {
                    dispatch({
                        type: TEST_DESTINATION_SUCCESS,
                        payload: response,
                    })
                }).catch((err) => {
                    console.log(err);
                    dispatch({
                        type: TEST_DESTINATION_SUCCESS,
                        payload: err,
                    })
                });
            } else {
                console.log('reqConfig:');
                console.log(reqConfig);
                axiosOld.request(reqConfig).then((response) => {
                    dispatch({
                        type: TEST_DESTINATION_SUCCESS,
                        payload: response,
                    })
                }).catch((err) => {
                    console.log(err);
                    dispatch({
                        type: TEST_DESTINATION_SUCCESS,
                        payload: err,
                    })
                });
            }





        } catch (err) {
            dispatch({
                type: TEST_DESTINATION_SUCCESS,
                payload: err,
            })
        }
    }
}

export const updateDestinationField = (field, value) => {
    console.log('in updateDestinationField');
    return {
        type: UPDATE_DESTINATION_FIELD,
        payload: { field, value }
    }
}

export const loadDestinations = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_DESTINATIONS,
            payload: null
        })
        axios.get(`${config.serviceUrl}/destinations/`).then((response) => {
            dispatch({
                type: LOAD_DESTINATIONS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const saveDestination = (destination: any, history: any) => {
    console.log('savedest');
    return (dispatch: any) => {
        if (destination.id) {
            delete destination.triggerId;
            console.log('putting');
            axios.put(`${config.serviceUrl}/destination/${destination.id}`, destination).then((response) => {
                console.log('put over resp', response);
                dispatch({
                    type: UPDATE_DESTINATION_SUCCESS,
                    payload: response
                })
                dispatch({
                    type: LOAD_DESTINATIONS,
                    payload: null
                })
                axios.get(`${config.serviceUrl}/destinations`).then((response) => {
                    dispatch({
                        type: LOAD_DESTINATIONS_SUCCESS,
                        payload: response.data.data
                    })
                })
            })
        } else {
            console.log('posting');
            axios.post(`${config.serviceUrl}/destination`, destination).then((response: any) => {
                dispatch({
                    type: CREATE_DESTINATION_SUCCESS,
                    payload: response
                })
                dispatch({
                    type: LOAD_DESTINATIONS,
                    payload: null
                })
                axios.get(`${config.serviceUrl}/destinations`).then((response) => {
                    dispatch({
                        type: LOAD_DESTINATIONS_SUCCESS,
                        payload: response.data.data
                    })
                })
            })
        }
    }
}