import React, { useState, useEffect } from 'react';
import { BsArrowDownUp, BsSliders } from "react-icons/bs";
import AppHeader from '../../components/header/header';
import { Search } from '../Search';
import FileUpload from './FileUpload';
import './suppressions.css'

const data = [
  {
    fileName: 'testfile1.csv',
    uploadedBy: 'Aaron Judge',
    uploadDate: 'March 31, 2022',
    uploadTime: 'at 8:00 PM',
  }, {
    fileName: 'testfile2.csv',
    uploadedBy: 'Blake Pell',
    uploadDate: 'March 30, 2022',
    uploadTime: 'at 8:00 PM',
  }, {
    fileName: 'testfile3.csv',
    uploadedBy: 'Christina Herschevik',
    uploadDate: 'March 29, 2022',
    uploadTime: 'at 8:00 PM',
  }, {
    fileName: 'testfile4.csv',
    uploadedBy: 'Destiny Child',
    uploadDate: 'March 28, 2022',
    uploadTime: 'at 8:00 PM',
  }, {
    fileName: 'testfile5.csv',
    uploadedBy: 'Everit Manning',
    uploadDate: 'March 27, 2022',
    uploadTime: 'at 8:00 PM',
  }, {
    fileName: 'testfile6.csv',
    uploadedBy: 'A Fredrick Freedman',
    uploadDate: 'March 31, 2022',
    uploadTime: 'at 8:00 PM',
  },
]

const Suppressions = (clientId) => {
  console.log(clientId);
  const [dataList, setDataList] = useState(clientId.suppressions)
  const [fileNameBool, setFileNameBool] = useState(false)
  const [uploadedByBool, setUploadedByBool] = useState(false)
  const [uploadedDateBool, setUploadedDateBool] = useState(false)
  const [showFileUploader, setShowFileUploader] = useState(false)

  useEffect(() => {
    setDataList(clientId.suppressions || []);
  }, [clientId.suppressions]);

  const sortByFileName = (bool) => {
    let newData;
    if (bool) {
      newData = dataList.sort((a, b) => {
        return (a['fileName'] >= b['fileName'] ? 1 : -1)
      })
    } else {
      newData = dataList.sort((a, b) => {
        return (a['fileName'] < b['fileName'] ? 1 : -1)
      })
    }

    setFileNameBool(!fileNameBool)
    return newData
  }

  const sortByUploadedBy = (bool) => {
    let newData;
    if (bool) {
      newData = dataList.sort((a, b) => {
        return (a['uploadedBy'] >= b['uploadedBy'] ? 1 : -1)
      })
    } else {
      newData = dataList.sort((a, b) => {
        return (a['uploadedBy'] < b['uploadedBy'] ? 1 : -1)
      })
    }

    setUploadedByBool(!uploadedByBool)
    return newData
  }

  const sortByUploadedDate = (bool) => {
    let newData;
    if (bool) {
      newData = dataList.sort((a, b) => {
        return (a['uploadDate'] > b['uploadDate'] ? 1 : -1)
      })
    } else {
      newData = dataList.sort((a, b) => {
        return (a['uploadDate'] < b['uploadDate'] ? 1 : -1)
      })
    }

    setUploadedDateBool(!uploadedDateBool)
    return newData
  }

  console.log(dataList);

  return (
    <>
      <AppHeader></AppHeader>
      <div id="header-section">
        <Search />
        <span>
          <button id="add-new" onClick={() => setShowFileUploader(true)}>+ Add New</button>
        </span>
      </div>

      {showFileUploader ? (
        <article id="file-upload">
          <FileUpload clientId={clientId} handleOnClick={setShowFileUploader} setDataList={setDataList} />
        </article>) : <></>}

      <table className="file-list__headings">
        <thead>
          <tr>
            <td><span className="file-headings">Suppressions<button className="sort-btn" onClick={() => sortByFileName(fileNameBool)}><BsArrowDownUp /></button></span></td>
            <td><span className="file-headings">Number of Suppressed Leads</span></td>
          </tr>
        </thead>
        <tbody>
          
            <tr key={`row-1`}>
              <td id="file-name-col">Client Suppression Total</td>
              <td id="date-col"><span id="upload-date">{dataList.count}</span></td>
            </tr>
          
        </tbody>
      </table>
    </>
  )
  /*<AppHeader></AppHeader>
      <div id="header-section">
        <Search />
        <span>
          <button id="filters" onClick={() => setShowFileUploader(true)}><BsSliders /> Filters</button>
          <button id="add-new" onClick={() => setShowFileUploader(true)}>+ Add New</button>
        </span>
      </div>

      <table className="file-list__headings">
        <thead>
          <tr>
            <td><span className="file-headings">File Name <button className="sort-btn" onClick={() => sortByFileName(fileNameBool)}><BsArrowDownUp /></button></span></td>
            <td><span className="file-headings">Uploaded By <button className="sort-btn" onClick={() => sortByUploadedBy(uploadedByBool)}><BsArrowDownUp /></button></span></td>
            <td><span className="file-headings">Upload Date <button className="sort-btn" onClick={() => sortByUploadedDate(uploadedDateBool)}><BsArrowDownUp /></button></span></td>
            <td><span className="file-headings">Actions</span></td>
          </tr>
        </thead>
        <tbody>
          {dataList.map((item, i) => {
            return <tr key={`row-${i}`}>
                <td id="file-name-col">{item.fileName}</td>
                <td id="uploaded-col">{item.uploadedBy}</td>
                <td id="date-col"><span id="upload-date">{item.uploadDate}</span><span id="upload-time">{item.uploadTime}</span></td>
                <td id="actions-col"><button className="action-btn">View</button><button className="action-btn">Delete</button></td>
              </tr>
          })}
        </tbody>
      </table>
      {showFileUploader ? (
        <article id="file-upload">
          <FileUpload handleOnClick={setShowFileUploader} setDataList={setDataList} />
        </article>) : <></>}*/
}

export default Suppressions
