import { CLEAR_SESSION, SET_SESSION, LOGOUT } from './types'
import cognitoUtils from '../../lib/cognitoUtils';

let session = localStorage.getItem('session');
session = session === null ? session : JSON.parse(session);

const initialState = {
  isLoggedIn: session === null ? false : true,
  session: session
}

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SESSION:
      localStorage.setItem('session', JSON.stringify(action.session) );
      return Object.assign({}, {
        session: action.session,
        isLoggedIn: true })

    case CLEAR_SESSION:
      return Object.assign({}, initialState);

    case LOGOUT:
      localStorage.removeItem('session');
      localStorage.removeItem('lastSelectedClient');
      document.location.href = cognitoUtils.getCognitoSignInUri();
      return Object.assign({}, initialState);

    default:
      return state
  }
}

export default authReducer