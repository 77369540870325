import React, { useState } from 'react';
import AppHeader from '../../components/header/header';

const FacebookPopup = (props) => {
    console.log(props);
    const url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=1066792504633035&scope=ads_management,ads_read&redirect_uri=https://app.smartrecognition.com/integration&state=${props.clientId}`;

    function changeHref() {
        window.location.href = url;
    }


    return (
        <>
        <AppHeader></AppHeader>
        <div>
            <p>When you complete the Facebook integration process we will create an Audience for your leads that will be submitted by SmartRecognition.</p>
            <button onClick={changeHref}>Facebook</button>
        </div>
        </> 
      )
    }
    
export default FacebookPopup