import React, { Component } from "react";

import { connect } from "react-redux";

import { AppState } from '../../store';
import { HashLoader } from "react-spinners";
import { NavLink as Link, withRouter } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";
import { IntegrationState } from "../../store/integrations/types";
import {ScriptsState} from "../../store/scripts/types"
import AppHeader from "../../components/header/header";
import BusinessProfileSection from "./BusinessProfileSection";
import GeneralDetailSection from "./GeneralDetailSection";
import BankingDetailSection from "./BankingDetailsSection";

import { emptyClient, updateClientField, loadClientById, saveClient, loadClients} from "../../store/clients/actions";
import { clearIntegrationMessage } from '../../store/integrations/actions';
import {loadClientsForAPI} from "../../store/clients/actions"
import {loadScriptById, saveScript, updateScriptField} from "../../store/scripts/actions"
import PasswordManagementSection from "./PasswordManagementSection";
import ScriptDetailsSectionSection from "./ScriptDetailsSection";
import { SystemState } from "../../store/system/types";

interface AccountsInterfaceProps {
  clients: ClientsState;
  system: SystemState;
  clearIntegrationMessage: typeof clearIntegrationMessage;
  emptyClient: typeof emptyClient;
  updateClientField: typeof updateClientField;
  updateScriptField: typeof updateScriptField;
  loadClients: typeof loadClients;
  loadClientById: typeof loadClientById;
  saveClient: typeof saveClient;
  saveScript: typeof saveScript;
  loadScriptById: typeof loadScriptById;
  scripts: ScriptsState;
  integrations: IntegrationState;
}

type MyState = {
  selectedProfileTab: string;
};

class Accounts extends React.Component<AccountsInterfaceProps, MyState> {
  constructor(props) {
    super(props);
    this.changeProfileTab = this.changeProfileTab.bind(this);
  }

  state: MyState = {
    // optional second annotation for better type inference
    selectedProfileTab: "generalDetails"
  };

  public componentDidMount(): void {
    /*loadClientsForAPI().then((res) =>{
      if(res.data && res.data.data && res.data.data.length && res.data.data[0]){
        this.setState({clientId: res.data.data[0].id})
        this.props.loadClients();
        this.props.loadClientById(res.data.data[0].id);
        this.props.loadScriptById(res.data.data[0].id);
      }
    })*/
    //this.props.loadClientById(this.props.system.selectedClient.id);
    //this.props.loadScriptById(this.props.system.selectedClient.id);
  }


  public changeProfileTab(profileTab: any): void {
    this.setState({ selectedProfileTab: profileTab });
  }

  public render(): JSX.Element {
    const props = this.props;
    const state = this.state;
    return (
      <div style={{ height: `calc(100vh - 6rem)` }}>
        <AppHeader></AppHeader>
        <div className="d-flex" style={{ height: "100%" }}>
          <BusinessProfileSection
            profileTab={state.selectedProfileTab}
            changeProfileTab={this.changeProfileTab}
            isAdmin={this.props.system.isAdmin}
          />
          {state.selectedProfileTab == "generalDetails" ? (
            <GeneralDetailSection
              emptyClient={props.emptyClient}
              clearIntegrationMessage={props.clearIntegrationMessage}
              integrations={props.integrations}
              clients={props.clients}
              updateClientField={props.updateClientField}
              saveClient={props.saveClient}
            />
          ) : null}
          {state.selectedProfileTab == "bankingDetails" ? (
              <BankingDetailSection
                emptyClient={props.emptyClient}
                clients={props.clients}
                updateClientField={props.updateClientField}
                saveClient={props.saveClient}
              />
            ) : null}
          {state.selectedProfileTab == "passwordMgmt" ? (
            <PasswordManagementSection
              emptyClient={props.emptyClient}
              clients={props.clients}
              updateClientField={props.updateClientField}
            />
          ) : null}
          {state.selectedProfileTab == "scriptDetails" ? (
              <ScriptDetailsSectionSection
                emptyClient={props.emptyClient}
                clients={props.clients}
                updateClientField={props.updateClientField}
                updateScriptField={props.updateScriptField}
                scripts={props.scripts}
                saveClient={props.saveClient}
                saveScript={props.saveScript}
              />
            ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  clients: state.clients,
  scripts: state.scripts,
  system: state.system,
  integrations: state.integrations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    emptyClient: () => dispatch(emptyClient()),
    clearIntegrationMessage: () => dispatch(clearIntegrationMessage()),
    updateClientField: (field, value) => dispatch(updateClientField(field, value)),
    loadClients: () => dispatch(loadClients()),
    loadClientById: id => dispatch(loadClientById(id)),
    saveClient: (offer) => dispatch(saveClient(offer)),
    loadScriptById: id => dispatch(loadScriptById(id)),
    saveScript: (script) => dispatch(saveScript(script)),
    updateScriptField: (field, value) => dispatch(updateScriptField(field, value))
  };
};


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Accounts)
);
