import React, { useState } from 'react';
import AppHeader from '../../components/header/header';
import './dashboard.css'


const Dashboard = () => {

  return (
    <>
      <AppHeader></AppHeader>
      <div className='dashboard'>
        <div>
          <h2 className='section_title'>You’re here… Awesome!</h2>
          <p>Time to multiply your leads, subscribers, & ROI.</p>
          <p> We’ve kept your dashboard clean & simple. Login into reporting here: <a href="https://portal.smartrecognition.com">https://portal.smartrecognition.com</a></p>

          <h3 className='section_title'>If you run into an issue or have questions though…</h3>
          <p>Reach out to our friendly team below:</p>
          <div className='dashboard_callout'>
            <p><strong>Email:</strong> <a href="mailto:support@smartrecognition.com">support@smartrecognition.com</a></p>
            <p><strong>Call:</strong> <a href="tel:+14079936505">(+1) 407-993-6505</a></p>
          </div>
          <button className='dashboard_button' onClick={() => window.open("https://www.smartrecognition.com/support/", '_blank')}>Documentation</button>
        </div>
      </div>
    </>
  )
}

export default Dashboard
