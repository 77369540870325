import React, {Component} from 'react';

import { connect } from "react-redux";
import { AppState } from '../../store';
import { withRouter } from 'react-router';
import { HashLoader } from "react-spinners";
import { NavLink as Link } from 'react-router-dom';
import { ClientsState } from '../../store/clients/types';
import AppHeader from '../../components/header/header'

import './accounts.css'


interface BusinessProfileSectionInterfaceProps {
    // clients: ClientsState;
    profileTab: any;
    changeProfileTab: Function;
    isAdmin: boolean;

}

class BusinessProfileSection extends Component<BusinessProfileSectionInterfaceProps> {

    public componentDidMount():void {
        // this.props.loadBusinessProfileSection();
    }

    public edit(id): void {
        // this.props.history.push(`/offer/${id}`);
    }

    public getClients(){
        return 1
    }

    public render(): JSX.Element {
        return (
            <div className='business_profile_section'>
              <div>
                <h4>Business Profile</h4>
              </div>
              <div onClick={() => this.props.changeProfileTab('generalDetails')} className={`section_tabs ${this.props.profileTab == 'generalDetails' ? 'active' : ''}`}>
                <h5>
                  General Details
                </h5>
                <p>
                  
                </p>
              </div>

              { this.props.isAdmin ? (<div onClick={() => this.props.changeProfileTab('bankingDetails')} className={`section_tabs ${this.props.profileTab == 'bankingDetails' ? 'active' : ''}`}>
                <h5>
                  Banking Details
                </h5>
                <p>
                  
                </p>
              </div>) : <></> }

              <div onClick={() => this.props.changeProfileTab('passwordMgmt')} className={`section_tabs ${this.props.profileTab == 'passwordMgmt' ? 'active' : ''}`}>
                <h5>
                  Password Management
                </h5>
                <p>
                  
                </p>
              </div>

              { this.props.isAdmin ? (<div onClick={() => this.props.changeProfileTab('scriptDetails')} className={`section_tabs ${this.props.profileTab == 'scriptDetails' ? 'active' : ''}`}>
                <h5>
                  Script Details
                </h5>
                <p>
                  
                </p>
              </div>) : <></> }

            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    clients: state.clients,
});

export default BusinessProfileSection
