import { LOAD_TRIGGERS_SUCCESS, UPDATE_TRIGGER_FIELD, LOAD_TRIGGER_BY_ID, LOAD_TRIGGER_BY_ID_SUCCESS, LOAD_TRIGGERS, UPDATE_TRIGGER_SUCCESS, CREATE_TRIGGER_SUCCESS, Trigger, REMOVE_TRIGGER_BY_ID, REMOVE_TRIGGER_BY_ID_SUCCESS,  SORT_TRIGGERS, SAVE_TRIGGER, DELETE_TRIGGER, DELETE_TRIGGER_SUCCESS } from "./types";
import store from '../../store';
import axios from '../../lib/axios';
import config from '../../config/app-config.json';

export const emptyTrigger = () => {
    return {
        type: CREATE_TRIGGER_SUCCESS,
        payload: null
    }
}

export const updateTriggerField = (field, value) => {
    return {
        type: UPDATE_TRIGGER_FIELD,
        payload: { field, value }
    }
}

export const deleteTrigger = id => {
    return (dispatch: any) => {
        dispatch({
            type: DELETE_TRIGGER,
            payload: null
        })
        axios.delete(`${config.serviceUrl}/trigger/${id}`).then((response) => {
            dispatch({
                type: DELETE_TRIGGER_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const loadTriggers = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_TRIGGERS,
            payload: null
        })
        axios.get(`${config.serviceUrl}/triggers/`).then((response) => {
            dispatch({
                type: LOAD_TRIGGERS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const loadTriggerById = id => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_TRIGGER_BY_ID,
            payload: null
        })
        axios.get(`${config.serviceUrl}/clients/${id}`).then((response) => {
            dispatch({
                type: LOAD_TRIGGER_BY_ID_SUCCESS,
                payload: response.data.data[0]
            })
        })
    }
  }

export const  loadTriggerById2 = data => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_TRIGGER_BY_ID_SUCCESS,
            payload: data
        })
    }
}

export const saveTrigger = (trigger: Trigger, history: any) => {
    return (dispatch: any) => {
        if(trigger.id) {
            dispatch({
                type: SAVE_TRIGGER,
                payload: null
            })
            axios.put(`${config.serviceUrl}/trigger/${trigger.id}`, trigger).then((response) => {
                dispatch({
                    type: UPDATE_TRIGGER_SUCCESS,
                    payload: response
                })
                dispatch({
                    type: LOAD_TRIGGERS,
                    payload: null
                })
                axios.get(`${config.serviceUrl}/triggers/`).then((response) => {
                    dispatch({
                        type: LOAD_TRIGGERS_SUCCESS,
                        payload: response.data.data
                    })
                })
            })
        } else {
            dispatch({
                type: SAVE_TRIGGER,
                payload: null
            })
            axios.post(`${config.serviceUrl}/trigger`, trigger).then((response: any) => {
                dispatch({
                    type: CREATE_TRIGGER_SUCCESS,
                    payload: response
                })
                dispatch({
                    type: LOAD_TRIGGERS,
                    payload: null
                })
                axios.get(`${config.serviceUrl}/triggers/`).then((response) => {
                    dispatch({
                        type: LOAD_TRIGGERS_SUCCESS,
                        payload: response.data.data
                    })
                })
            })
        }
    }
}