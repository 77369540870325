import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { clientsReducer } from "./clients/reducers"
import { destinationsReducer } from "./destinations/reducers";
import { reportReducer } from "./reports/reducers";
import { scriptsReducer } from "./scripts/reducers";
import { triggersReducer } from "./triggers/reducers";
import { suppressionReducer } from "./suppression/reducers"
import authReducer from "./auth/reducers";
import systemReducer from "./system/reducers";
import { integrationReducer } from "./integrations/reducers";



const rootReducer = combineReducers({
  clients: clientsReducer,
  authReducer: authReducer,
  destinations: destinationsReducer,
  report: reportReducer,
  scripts: scriptsReducer,
  triggers: triggersReducer,
  integrations: integrationReducer,
  suppressions: suppressionReducer,
  system: systemReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export let store: any;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const newStore = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  store = newStore;

  return newStore;
}
