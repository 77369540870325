import React, { Component } from "react";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { withRouter } from "react-router";
import { HashLoader } from "react-spinners";
import { NavLink as Link } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";
import { IntegrationState } from "../../store/integrations/types";
import { BsFacebook, BsBookmarkCheckFill } from "react-icons/bs";
import "./accounts.css";

interface GeneralDetailSectionInterfaceProps {
  clients: ClientsState;
  emptyClient: Function;
  updateClientField: Function;
  saveClient: Function;
  integrations: IntegrationState;
  clearIntegrationMessage: Function;
}

type MyState = {
  isEdit: boolean;
  showMessage: boolean;
};

class GeneralDetailSection extends Component<
  GeneralDetailSectionInterfaceProps,
  MyState
> {
  state: MyState = {
    isEdit: false,
    showMessage: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.editAccount = this.editAccount.bind(this);
  }

  public componentDidMount(): void {
    this.handleMessage()
   }

  public editAccount = (event: boolean, type?: any) => {
    this.setState({ isEdit: event });
    if (event == false && type == "save") {
      this.props.saveClient(this.props.clients.editedClient);
    }
  };

  private handleChange(field: string, value: any): void {
    this.props.updateClientField(field, value);
  }

  private startIntegration(type: string): void {
    const url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=1066792504633035&scope=ads_management&redirect_uri=https://app.smartrecognition.com/integration&state=${this.props.clients.editedClient.id}`;
    window.location.href = url;
  }

  private handleMessage(): void {
    if(this.props.integrations.message) {
      this.setState({showMessage: true})
      setTimeout(() => {
        this.setState({showMessage: false})
      }, 4000)
      setTimeout(() => {
        this.props.clearIntegrationMessage()
      }, 6500)
  }
}

  public render(): JSX.Element {
    const { integrations, clients } = this.props; // Access integrations from props
    const { isEdit, showMessage } = this.state;

    const fbBool = clients.editedClient.facebook
    const integrationMessage = integrations.message;

    return (
      <div className="general_detail_section">
        {<div
            id="integration-message"
            className={`${showMessage ? 'show' : 'hide'} integration_message--${integrationMessage.includes('Success') ? 'success' : 'error'}`}
          >
            {integrationMessage}
          </div>}
        <div className="section_title">
          <h2>General Details</h2>
          {isEdit ? (
            <div>
              <button
                className="button secondary mr-3"
                onClick={() => this.editAccount(false)}
              >
                Cancel
              </button>
              <button
                className="button accent"
                onClick={() => {
                  this.editAccount(false, "save");
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <button
              className="button accent"
              onClick={() => this.editAccount(true)}
            >
              Edit
            </button>
          )}
        </div>

        <div className="form_details">
          <div className="form_title">Business Information</div>

          <div className="flexBox">
            <div id="w50">
              <label className="field_label">Name</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.clientName}
                  onChange={(event) =>
                    this.handleChange("clientName", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Email</label>
              <div>
                <input
                  required
                  type="email"
                  value={clients.editedClient.clientEmail}
                  onChange={(event) =>
                    this.handleChange("clientEmail", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Phone</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.contactPhone}
                  onChange={(event) =>
                    this.handleChange("contactPhone", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Contact name</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.contactName}
                  onChange={(event) =>
                    this.handleChange("contactName", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Contact email</label>
              <div>
                <input
                  required
                  type="email"
                  value={clients.editedClient.contactEmail}
                  onChange={(event) =>
                    this.handleChange("contactEmail", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Contact phone</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.contactPhone}
                  onChange={(event) =>
                    this.handleChange("contactPhone", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form_details">
          <div className="form_title">Business Locale</div>
          <div className="flexBox">
            <div id="w100">
              <label className="field_label">Address</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.addressLine1}
                  onChange={(event) =>
                    this.handleChange("addressLine1", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w100">
              <label className="field_label">Address 2</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.addressLine2}
                  onChange={(event) =>
                    this.handleChange("addressLine2", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w31">
              <label className="field_label">City</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.city}
                  onChange={(event) =>
                    this.handleChange("city", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w31">
              <label className="field_label">State</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.state}
                  onChange={(event) =>
                    this.handleChange("state", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w31">
              <label className="field_label">Zipcode</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.zip}
                  onChange={(event) =>
                    this.handleChange("zip", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">City</label>
              <div>
                <input
                  required
                  type="email"
                  value={clients.editedClient.city}
                  onChange={(event) =>
                    this.handleChange("city", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Country or Region</label>
              <div>
                <input
                  required
                  type="text"
                  value={clients.editedClient.state}
                  onChange={(event) =>
                    this.handleChange("state", event.target.value)
                  }
                  disabled={!isEdit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form_details">
          <div className="form_title">Integrations</div>
          <div className="flexBox">
            <div id="w100">
              <label className="field_label" style={{"display": "none"}}>Facebook</label>
              <div>
                <button
                  className={`fb__button ${fbBool ? 'disabled' : ''}`}
                  disabled={fbBool}
                  type="button"
                  title="Facebook"
                  onClick={(event) =>
                    this.startIntegration("facebook")
                  }
                >
                  <p className="fb__button--icon">{ fbBool  ? <BsBookmarkCheckFill /> :  <BsFacebook />}</p>
                  <p className="fb__button--text">{ fbBool ? 'Facebook Integrated' : 'Click to Start'}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// mapStateToProps function to inject the part of the state into the component's props

// Use the connect HOC to connect your component to Redux
export default GeneralDetailSection;
