import {
    ScriptsState,
    LOAD_SCRIPTS,
    LOAD_SCRIPTS_SUCCESS,
    UPDATE_SCRIPT_FIELD,
    LOAD_SCRIPT_BY_ID,
    LOAD_SCRIPT_BY_ID_SUCCESS,
    CREATE_SCRIPT_SUCCESS,
    UPDATE_SCRIPT_SUCCESS,
    REMOVE_SCRIPT_BY_ID,
    REMOVE_SCRIPT_BY_ID_SUCCESS,
    SORT_SCRIPTS,
    ScriptActionTypes
} from './types';

import { sortByField } from "../../lib/sort";

const emptyScript: any = {
    clientId: '',
    code: '',
    id: ''

}

const initialSort = {
    field: 'end',
    direction: false
}

const initialState: ScriptsState = {
    loading: false,
    loadingScript: false,
    scripts: [],
    scriptsSortOptions: Object.assign({}, initialSort),
    editedScript: Object.assign({}, emptyScript),
};

export function scriptsReducer(
    state = initialState,
    action: ScriptActionTypes
): ScriptsState {
    switch (action.type) {
        case LOAD_SCRIPTS:
            return {
                ...state,
                scripts: [],
                loading: true
            }
        case LOAD_SCRIPTS_SUCCESS:
            return {
                ...state,
                scripts: sortByField(state.scriptsSortOptions.field, state.scriptsSortOptions.direction, action.payload),
                loading: false
            };

        case LOAD_SCRIPT_BY_ID:
            return {
                ...state,
                loadingScript: true
            };

        case LOAD_SCRIPT_BY_ID_SUCCESS:
            return {
                ...state,
                editedScript: action.payload,
                loadingScript: false
            };

        case CREATE_SCRIPT_SUCCESS:
            return {
                ...state,
                editedScript: Object.assign({}, emptyScript)
            };

        case UPDATE_SCRIPT_SUCCESS:
            return {
                ...state
            };

        case UPDATE_SCRIPT_FIELD:
            let editedScript : any = Object.assign({}, state.editedScript);
            editedScript[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedScript
            }

        case SORT_SCRIPTS:
            state.scriptsSortOptions.direction = !state.scriptsSortOptions.direction;
            state.scriptsSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                scripts: sortByField(state.scriptsSortOptions.field, state.scriptsSortOptions.direction, state.scripts),
                scriptsSortOptions: Object.assign({}, state.scriptsSortOptions)
            }

        default:
            return state;
    }
}
