export const LOAD_CLIENTS = "LOAD_CLIENTS";
export const LOAD_CLIENTS_SUCCESS = "LOAD_CLIENTS_SUCCESS";
export const UPDATE_CLIENT_FIELD = "UPDATE_CLIENT_FIELD";
export const LOAD_CLIENT_BY_ID = "LOAD_CLIENT_BY_ID";
export const LOAD_CLIENT_BY_ID_SUCCESS = "LOAD_CLIENT_BY_ID_SUCCESS";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const REMOVE_CLIENT_BY_ID = "REMOVE_CLIENT_BY_ID";
export const REMOVE_CLIENT_BY_ID_SUCCESS = "REMOVE_CLIENT_BY_ID_SUCCESS";
export const SORT_CLIENTS = "SORT_CLIENTS";
export const FILTER_CLIENTS = "FILTER_CLIENTS";

export interface ClientsState {
    clients: Array<Client>
    clientsSortOptions: {
        field: string
        direction: boolean
    }
    clientsFiltered: Array<Client>
    editedClient: Client
    loading: boolean
    loadingClient: boolean
}

export interface Client {
    id?: number
    clientName: string
    clientEmail: string
    contactName: string
    contactEmail: string
    contactPhone: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    bankName: string
    routingNumber: string
    accountNumber: string
    facebook: boolean
    frequency: number | string | null;
}

interface LoadClients {
    type: typeof LOAD_CLIENTS_SUCCESS | typeof LOAD_CLIENTS,
    payload: Array<Client>
}

interface LoadClientById {
    type: typeof LOAD_CLIENT_BY_ID_SUCCESS | typeof LOAD_CLIENT_BY_ID,
    payload: Client
}

interface UpdateClientField {
    type: typeof UPDATE_CLIENT_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateClient {
    type: typeof UPDATE_CLIENT_SUCCESS
    payload: any
}

interface CreateClient {
    type: typeof CREATE_CLIENT_SUCCESS
    payload: any
}

interface RemoveClient {
    type: typeof REMOVE_CLIENT_BY_ID_SUCCESS
    payload: any
}

interface SortClients {
    type: typeof SORT_CLIENTS,
    payload: any
}

interface FilterClients {
    type: typeof FILTER_CLIENTS,
    payload: any
}

interface DefaultAction {
    type: any
    payload?: any
}

export type ClientActionTypes = LoadClients | UpdateClientField | LoadClientById | LoadClients | CreateClient | UpdateClient | RemoveClient | DefaultAction | SortClients;
