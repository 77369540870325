import React, { Component } from "react";

import { connect } from "react-redux";

import { AppState } from '../../store';
import { HashLoader } from "react-spinners";
import { NavLink as Link, withRouter } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";
import AppHeader from "../../components/header/header";
import { loadClients } from "../../store/clients/actions";
import { SystemState } from "../../store/system/types";
import Spinner from "../../components/spinner";
import { IntegrationState } from "../../store/integrations/types";
import { loadIntegrations } from "../../store/integrations/actions";
import FacebookPopup from "./facebookIntegration";

interface IntegrationInterfaceProps {
  loadClients: typeof loadClients;
  loadIntegrations: typeof loadIntegrations;
  clients: ClientsState;
  system: SystemState;
  integrations: IntegrationState;
}

class Integrations extends React.Component<IntegrationInterfaceProps> {
  constructor(props) {
    console.log(props);
    super(props);
    //this.props.loadClients();
  }



  public componentDidMount(): void {
    this.props.loadIntegrations();
  }


  public render(): JSX.Element {
    const props = this.props;
    const state = this.state;
    //this.props.loadClients();
    console.log(this);

    return (
      this.props.system.selectedClient == null ? <></> : <FacebookPopup clientId={this.props.system.selectedClient.id} />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  clients: state.clients,
  scripts: state.scripts,
  system: state.system,
  integrations: state.integrations
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadClients: () => dispatch(loadClients()),
    loadIntegrations: () => dispatch(loadIntegrations())
  };
};


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Integrations)
);