import {
    TriggersState,
    LOAD_TRIGGERS,
    LOAD_TRIGGERS_SUCCESS,
    UPDATE_TRIGGER_FIELD,
    LOAD_TRIGGER_BY_ID,
    LOAD_TRIGGER_BY_ID_SUCCESS,
    CREATE_TRIGGER_SUCCESS,
    UPDATE_TRIGGER_SUCCESS,
    SORT_TRIGGERS,
    SAVE_TRIGGER,
    TriggerActionTypes,
    DELETE_TRIGGER,
    DELETE_TRIGGER_SUCCESS
} from './types';

import { sortByField } from "../../lib/sort";

const date =  new Date();
var endDate = new Date();
endDate.setDate(endDate.getDate() + 30);

const emptyTrigger: any = {
    clientId: '0', //hardcoding until client choosing functionality implementation
    name: '',
    destinationIds: '',
    description: '',
    status: '1', // //hardcoding until client choosing functionality implementation
    startDate: date.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
    filterOptions: '',
    scriptId: '',
    dynamicData: ''
}

const initialSort = {
    field: 'end',
    direction: false
}

const initialState: TriggersState = {
    loading: false,
    loadingTrigger: false,
    triggers: [],
    triggersSortOptions: Object.assign({}, initialSort),
    editedTrigger: Object.assign({}, emptyTrigger),
};

export function triggersReducer(
    state = initialState,
    action: TriggerActionTypes
): TriggersState {
    switch (action.type) {
        case DELETE_TRIGGER:
            return {
                ...state,
                loading: true
            };

        case DELETE_TRIGGER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case LOAD_TRIGGERS:
            return {
                ...state,
                triggers: [],
                loading: true
            };
        case LOAD_TRIGGERS_SUCCESS:
            return {
                ...state,
                triggers: sortByField(state.triggersSortOptions.field, state.triggersSortOptions.direction, action.payload),
                loading: false
            };

        case LOAD_TRIGGER_BY_ID:
            return {
                ...state,
                loadingTrigger: true
            };

        case LOAD_TRIGGER_BY_ID_SUCCESS:
            return {
                ...state,
                editedTrigger: action.payload,
                loadingTrigger: false
            };
         
        case SAVE_TRIGGER:
            return {
                ...state,
                triggers: [],
                loading: true
            }

        case CREATE_TRIGGER_SUCCESS:
            return {
                ...state,
                editedTrigger: Object.assign({}, emptyTrigger)
            };

        case UPDATE_TRIGGER_SUCCESS:
            return {
                ...state
            };

        case UPDATE_TRIGGER_FIELD:
            let editedTrigger : any = Object.assign({}, state.editedTrigger);
            editedTrigger[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedTrigger
            }

        case SORT_TRIGGERS:
            state.triggersSortOptions.direction = !state.triggersSortOptions.direction;
            state.triggersSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                triggers: sortByField(state.triggersSortOptions.field, state.triggersSortOptions.direction, state.triggers),
                triggersSortOptions: Object.assign({}, state.triggersSortOptions)
            }

        default:
            return state;
    }
}
