import {
    CHANGE_ACCOUNT,
    IntegrationActionTypes,
    IntegrationState,
    LOAD_INTEGRATIONS,
    LOAD_INTEGRATIONS_SUCCESS,
    SAVE_INTEGRATION,
    SAVE_INTEGRATION_PENDING,
    SAVE_INTEGRATION_SUCCESS,
    CLEAR_MESSAGE,
} from './types';

import { sortByField } from "../../lib/sort";



const emptyIntegration: any = {
    name: '',
    type: '',
    authentication_method: ''
}

const initialSort = {
    field: 'end',
    direction: false,
}

const initialState: IntegrationState = {
    loading: false,
    loadingIntegration: false,
    integrations: [],
    integrationsSortOptions: Object.assign({}, initialSort),
    adAccountLists: [],
    selectedAccount: '',
    redirectUser: false,
    message: '',
};

export function integrationReducer(
    state = initialState,
    action: IntegrationActionTypes
): IntegrationState {
    switch (action.type) {
        case LOAD_INTEGRATIONS:
            return {
                ...state,
                integrations: [],
                loading: true,
            };
        case LOAD_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                integrations: sortByField(state.integrationsSortOptions.field, state.integrationsSortOptions.direction, action.payload),
                loading: false,
            };

        case SAVE_INTEGRATION:
            return {
                ...state,
                integrations: [],
                loading: true,
            }

        case SAVE_INTEGRATION_PENDING:
            return {
                ...state,
                adAccountLists: action.payload.data.data.accounts.data,
                loading: false,
                message: 'Pending...',
            }

        case SAVE_INTEGRATION_SUCCESS:
            let message;
            if(action.payload.status >= 200 && action.payload.status < 300) {
                message = 'Success! Your account has been integrated.'
            } else {
                message = `${action.payload}`
            }

            return {
                ...state,
                redirectUser: true,
                loading: false,
                message,
            };

        case CHANGE_ACCOUNT:
            return {
                ...state,
                selectedAccount: action.payload,
            }

        case CLEAR_MESSAGE:
            return {
                ...state,
                message: '',
            }

        default:
            return state;
    }
}
