export function containsObject(obj, list) {

    return Array.from(list).some((e: any) => Object.entries(e).toString() === Object.entries(obj).toString())
}

export function removeDuplicateObjects(array, property) {
    const uniqueIds: any = [];
  
    const unique = array.filter(element => {
      const isDuplicate = uniqueIds.includes(element[property]);
  
      if (!isDuplicate) {
        uniqueIds.push(element[property]);
  
        return true;
      }
  
      return false;
    });
  
    return unique;
  }