import { Client } from "../clients/types";

export const MENU_TOGGLE = 'MENU_TOGGLE';
export const CLIENT_MENU_TOGGLE = "CLIENT_MENU_TOGGLE";
export const SELECT_CLIENT = "SELECT_CLIENT";
export const SELECT_OWN_CLIENT = "SELECT_OWN_CLIENT";
export const CHECK_ADMIN_RIGHTS = "CHECK_ADMIN_RIGHTS";

export interface SystemState {
    menuVisible: boolean
    isAdmin: boolean
    clientsExpanded: boolean
    selectedClient: Client
}
