import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { initSessionFromCallbackURI } from '../../store/auth/actions';
import { loadClients } from '../../store/clients/actions';

const mapStateToProps = state => {
  return { session: state.authReducer.session, isLoggedIn: state.authReducer.isLoggedIn }
}

const mapDispatchToProps = dispatch => {
  return {
    initSessionFromCallbackURI: href => dispatch(initSessionFromCallbackURI(href)),
    loadAccounts: () => dispatch(loadClients() )
  }
}

class Callback extends Component<any> {
  componentDidMount () {
    if (this.props.location && (this.props.location.hash || this.props.location.search)) {
      this.props.initSessionFromCallbackURI(window.location.href);

    }
  }

  componentWillUpdate() {
    this.props.loadAccounts();
  }

  render () {
    console.log(this);
    if(this.props.isLoggedIn) {
      return <Redirect to='/accounts' />
    } else {
      return null
    }
  }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Callback)
)
