import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Accounts from './containers/Accounts/';
import Suppressions from './containers/Suppressions';
import Reports from './containers/Reports';
import { Provider } from "react-redux";
import configureStore from "./store";
import Menu from './components/menu/menu'
import Dashboard from './containers/Dashboard';
import Triggers from './containers/Triggers';
import Auth from './containers/auth';
import Callback from './containers/auth/callback';
import Integration from './containers/Integration';
import Facebook from './containers/Facebook';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        {/* <ThemeProvider theme={theme}> */}
        <Router>
            <Switch>
                <Route path="/callback">
                    <Callback />
                </Route>
            </Switch>
            <Menu>
                <Switch>
                    <Route path="/dashboard">
                        <Auth />
                        <Dashboard />
                    </Route>
                    <Route path="/accounts">
                        <Auth />
                        <Accounts />
                    </Route>
                    <Route path="/suppression">
                        <Auth />
                        <Suppressions />
                    </Route>
                    <Route path="/triggers">
                        <Auth />
                        <Triggers />
                    </Route>
                    <Route path="/reports">
                        <Auth />
                        <Reports />
                    </Route>
                    <Route path="/integration">
                        <Auth />
                        <Integration />
                    </Route>
                    <Route path="/facebook">
                        <Auth />
                        <Facebook />
                    </Route>
                    <Route exact path="/" component={() => <Redirect to='/accounts' />} />
                </Switch>
            </Menu>
        </Router>
        {/* </ThemeProvider> */}
    </Provider>,
    document.getElementById('root')
);
