import {
    SuppressionState,
    LOAD_SUPPRESSIONS,
    LOAD_SUPPRESSIONS_SUCCESS,
    SORT_SUPPRESSIONS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_STATE,
    SuppressionsActionTypes,
  } from "./types";
  import { sortByField } from "../../lib/sort";

  const initialSort = {
    field: 'timestamp',
    direction: false
}

const emptyList: any = {
  id: '',
  filename: '',
  all: '',
  started: '',
  finished: ''
}

  const initialState: SuppressionState = {
    loading: false,
    suppressionSortOptions: initialSort,
    list: [emptyList]
  };

  export function suppressionReducer(
    state = initialState,
    action: SuppressionsActionTypes
  ): SuppressionState {
    console.log('ACTION:', action)
    switch (action.type) {
      case LOAD_SUPPRESSIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload.data
      };

      case LOAD_SUPPRESSIONS:
        return {
          ...state,
          loading: true,
        };

      case SORT_SUPPRESSIONS:
        state.suppressionSortOptions.direction = !state.suppressionSortOptions.direction;
        state.suppressionSortOptions.field = action.payload.fieldValue;
        return {
            ...state,
            list: sortByField(state.suppressionSortOptions.field, state.suppressionSortOptions.direction, state.list),
            suppressionSortOptions: Object.assign({}, state.suppressionSortOptions)
        };

        case RESET_STATE:
          return {...initialState}

    default:
      return state;
    }
  }
