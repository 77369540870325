import React from 'react';
import './menu.css';
import { withRouter } from "react-router";
import { NavLink as Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from '../../store';

import { ReactComponent as AccountIcon } from '../../icons/account.svg';
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg';
import { ReactComponent as ReportIcon } from '../../icons/report.svg';
import { ReactComponent as SuppressionIcon } from '../../icons/suppression.svg';
import { ReactComponent as TriggerIcon } from '../../icons/trigger.svg';
import { menuToggle } from '../../store/system/actions';
import { loadClients } from '../../store/clients/actions';
import { selectClient } from '../../store/system/actions';


const mapDispatchToProps = dispatch => {
    return {
        loadClients: () => dispatch(loadClients()),
        menuToggle: () => dispatch(menuToggle())
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    clients: state.clients
})


class AppMenu extends React.Component<any> {
    componentDidMount() {
        if (document.location.href.indexOf('callback') === -1) {
            console.log(this.props);
            this.props.loadClients();
        }
    }
    render() {
        console.log(this);
        return (
            <div>
                <div className={`menu visible`}>
                    <a className="app__logo" href="/">
                        <img alt="" src="/smart-recognition-logo-white.png" />
                    </a>

                    <hr className=''></hr>

                    <div className="app__menu-links">
                        <Link to="/dashboard">
                            <DashboardIcon />
                            Dashboard
                        </Link>
                        {this.props.system.isAdmin == true ?
                            <Link to="/triggers">
                                <TriggerIcon />
                                Triggers
                            </Link> 
                            :
                            ''
                        }
                        <Link to="/accounts">
                            <AccountIcon />
                            Accounts
                        </Link>
                        <Link to="/suppression">
                            <SuppressionIcon />
                            Suppression
                        </Link>
                        <Link to="/reports">
                            <ReportIcon />
                            Reports
                        </Link>
                    </div>
                </div>

                <div className={`app__container app__container--menu-visible`}>
                    {this.props.children}
                </div>
            </div>
        )
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppMenu));
