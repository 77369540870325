import React, { Component } from "react";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { NavLink as Link } from "react-router-dom";
import AppHeader from "../../components/header/header";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import Select from 'react-select';
import { ReportState } from '../../store/reports/types';
import {
    loadReport,
    sortReport,
    filterByDate,
    filterByDateType,
    resetState,
} from '../../store/reports/actions';
import { loadClientById, loadClients, loadClientsForAPI } from "../../store/clients/actions"
import Spinner from "../../components/spinner";
import { SystemState } from "../../store/system/types";
import { ScriptsState } from "../../store/scripts/types"
import { loadScriptById } from "../../store/scripts/actions"

import './style.css';
//import DataTable from '../../components/dataTable';

const spinnerStyle = `
    margin: 100px auto;
`

const dateFilters = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 3 days', value: 'last 3 days' },
    { label: 'Last 7 days', value: 'last 7 days' },
    { label: 'Last 30 days', value: 'last 30 days' },
    { label: 'All Time', value: 'all Time' },
    { label: 'Custom', value: 'custom' }
];

const cols = [{
    fieldName: "Email",
    fieldValue: "email",
    fieldType: 'string'
}, {
    fieldName: "Full URL",
    fieldValue: "fullurl",
    fieldType: 'string'
}, {
    fieldName: "Fingerprint",
    fieldValue: "fingerprint",
    fieldType: 'string'
}, {
    fieldName: "Timestamp",
    fieldValue: "timestamp",
    fieldType: 'string'
}];

interface ReportInterfaceProps {
    report: ReportState
    system: SystemState
    loadReport: typeof loadReport
    filterByDate: typeof filterByDate
    filterByDateType: typeof filterByDateType
    sortReport: typeof sortReport
    resetState: typeof resetState
    loadScriptById: typeof loadScriptById;
    scripts: ScriptsState
}

class Reports extends React.Component<ReportInterfaceProps & RouteComponentProps> {

    public componentDidMount(): void {
        const filter = { to: this.props.report.filterByDate.to, from: this.props.report.filterByDate.from }

        //this.props.loadReport(filter, this.props.report.filterByDateType);
        this.props.resetState()
    }

    private loadReport(presetFilter: any = null): void {
        const filter = presetFilter == null ? this.props.report.filterByDate : presetFilter;

        this.props.loadReport(filter, this.props.report.filterByDateType, this.props.scripts.editedScript.id);
    }

    async handleChange(name: string, value: any) {
        console.log(name);
        console.log(value);
        if (name === 'filterDateType') {
            await this.props.filterByDateType(value);

            this.loadReport();
        }
        if (name === 'filterDateTo') {
            console.log(value);
            console.log(new Date(value));
            const filter = { to: value, from: this.props.report.filterByDate.from }
            this.props.filterByDate(filter);
        }
        if (name === 'filterDateFrom') {
            console.log(value);
            console.log(new Date(value));
            const filter = { to: this.props.report.filterByDate.to, from: value }
            this.props.filterByDate(filter);
        }
    }

    public render(): JSX.Element {
        console.log(this);
        return (
            <div>
                <AppHeader></AppHeader>
                <div>

                </div>
                <div className="report-details">
                    Filter By Date
                    <Select
                        //label="Date"
                        value={this.props.report.filterByDateType}
                        onChange={event =>
                            this.handleChange('filterDateType', event)
                        }
                        options={dateFilters}
                    />

                    {this.props.report.filterByDateType.value === 'custom' ? (
                        <>
                            From
                            <DatePicker
                                label="From"
                                selected={this.props.report.filterByDate.from}
                                onChange={event =>
                                    this.handleChange('filterDateFrom', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                            To
                            <DatePicker
                                label="To"
                                selected={this.props.report.filterByDate.to}
                                onChange={event =>
                                    this.handleChange('filterDateTo', event)
                                }
                                dateFormat="yyyy-MM-dd"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </DatePicker>
                        </>
                    ) : null}
                    {this.props.report.report.length > 0 ?
                        <>
                            <h1>Ready to Export!</h1>
                            <a href={this.props.report.report} className='downloadbutton' >
                                <span className='download'>
                                    Export to CSV, Click Here
                                </span>
                            </a>
                        </> :
                        <HashLoader
                            //css={spinnerStyle}
                            size={100}
                            color={"#297DAC"}
                            loading={this.props.report.loading} />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    report: state.report,
    system: state.system,
    scripts: state.scripts,
    loadReport: loadReport,
    filterByDateType: filterByDateType,
    filterByDate: filterByDate,
    loadScriptById: loadScriptById,
    loadClientById: loadClientById,
    loadClients: loadClients,
    loadClientsForAPI: loadClientsForAPI
});

export default withRouter(
    connect(mapStateToProps, {
        loadReport,
        sortReport,
        filterByDateType,
        filterByDate,
        loadScriptById,
        resetState,
        loadClientById,
        loadClients,
        loadClientsForAPI
    })(Reports)
);
