import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { AppState } from '../../store';
import { ClientsState } from '../../store/clients/types';
import axios from '../../lib/axios';
import appConfig from '../../config/app-config.json';

import './accounts.css';

interface PasswordManagementSectionInterfaceProps {
  clients: ClientsState;
  emptyClient: Function;
  updateClientField: Function;
}

export default function PasswordManagementSection(props: PasswordManagementSectionInterfaceProps) {
  const [message, setMessage] = useState<null | boolean>(null);
  const newPasswordRef = useRef<string>('');

  // Mimicking componentDidMount with useEffect
  useEffect(() => {
    // Component did mount logic here
  }, []); // Empty dependency array means this effect runs once on mount

  const handleChange = (field: string, value: any) => {
    // Update local mutable ref and dispatch Redux action
    props.updateClientField(field, value);
    newPasswordRef.current = value;
  };

  const submit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    axios.post(`${appConfig.serviceUrl}/SREC-password-admin-reset?email=${props.clients.editedClient.clientEmail}`)
    //axios.post(`${appConfig.email}`)
    // axios.post(`http://localhost:1128/testing/${props.clients.editedClient.clientEmail}`)
      .then((res) => {
        console.log(res);
        setMessage(true);
      })
      .catch((err: any) => {
        console.error(err)
        setMessage(false);
      })
  };

  const display = () => {
    if (message === true) {
      return (
        <div className='form_title' style={{color: "#194bfb"}}>
          Reset Successful!
        </div>
      )
    } else if (message === false) {
      return (
        <div className='form_title' style={{color: "red"}}>
          An Error Occurred
        </div>
      )
    }
  }

  return (
    <div className='general_detail_section'>
      <div className='section_title'>
        <h2>Password Management</h2>
      </div>
      {display()}
      <div className='form_details'>
        <div className='form_title'>
          Reset Password
        </div>
        <div className="flexBox mb-3">
          <div id='w50'>
            <button className='button accent' onClick={submit}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


// import React, {Component} from 'react';

// import { connect } from "react-redux";
// import { AppState } from '../../store';
// import { withRouter } from 'react-router';
// import { HashLoader } from "react-spinners";
// import { NavLink as Link } from 'react-router-dom';
// import { ClientsState } from '../../store/clients/types';

// import './accounts.css'

// interface PasswordManagementSectionInterfaceProps {
//     clients: ClientsState;
//     emptyClient: Function;
//     updateClientField: Function;
// }

// type MyState = {
//   newPassword: string;
// };

// class PasswordManagementSection extends Component<PasswordManagementSectionInterfaceProps> {
//   constructor(props: PasswordManagementSectionInterfaceProps) {
//     super(props);
//     this.state = {
//         newPassword: '' // Initialize the password in state
//     };
//     this.submit = this.submit.bind(this); // Correctly binding the submit function
// }

//     public componentDidMount():void {
//     }

//     public edit(id): void {
//     }

//     public getClients(){
//         return 1
//     }

//     private handleChange = (field: string, value: any): void => {
//       // Update local state instead of dispatching Redux action directly
//       this.props.updateClientField(field, value);
//       this.setState({ [field]: value });
//   };


//   private submit = (e: React.FormEvent<HTMLButtonElement>): void => {
//     e.preventDefault();
//     console.clear();
//     console.log("New Password:", Object.values(this.state)[0]);
//     console.log("Client Email:", this.props.clients.editedClient.clientEmail);
// };

//     public render(): JSX.Element {

//         return (
//             <div className='general_detail_section'>
//               <div className='section_title'>
//                 <h2>
//                   Password Management
//                 </h2>
//               </div>

//               <div className='form_details'>
//                 <div className='form_title'>
//                   Update Password
//                 </div>
//                 <div className="flexBox mb-3">
//                     <div id='w50'>
//                       <label className='field_label'>New password</label>
//                       <div>
//                           <input required type="password"
//                           // value={this.props.clients.editedClient.client_name}
//                           //value='Contact@samplecompany.com'
//                           onChange={event => this.handleChange('newPassword', event.target.value)}
//                           placeholder='Enter new password...'
//                           />
//                       </div>
//                     </div>
//                 </div>
//                 <button className='button accent' onClick={this.submit}>
//                   Confirm
//                 </button>
//               </div>
//             </div>
//         )
//     }
// }

// export default PasswordManagementSection