import React from "react";
import { RotatingLines } from "react-loader-spinner";
import "./spinner.css"

interface SpinnerInterface {}

const Spinner: React.FC<SpinnerInterface> = () => {
  return (
    <div className="spinner_container">
    <RotatingLines
      strokeColor="blue"
      strokeWidth="3"
      animationDuration="1.25"
      width="96"
      visible={true}
    />
    </div>
  );
};

export default Spinner;
