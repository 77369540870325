import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from '../../store';
import { HashLoader } from "react-spinners";
import { NavLink as Link, withRouter } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";
import { ScriptsState } from "../../store/scripts/types"
import AppHeader from "../../components/header/header";
import Suppression from "./Suppressions";
import { loadClients } from "../../store/clients/actions";
import { SystemState } from "../../store/system/types";
import Spinner from "../../components/spinner";
import { SuppressionState } from "../../store/suppression/types";
import { loadSuppressions } from "../../store/suppression/actions";
import { integer } from "aws-sdk/clients/cloudfront";

interface SuppressionInterfaceProps {
  loadClients: typeof loadClients;
  loadSuppressions: typeof loadSuppressions;
  clients: ClientsState;
  system: SystemState;
  scripts: ScriptsState;
  suppressions: SuppressionState;
}

const Suppressions: React.FC<SuppressionInterfaceProps> = (props) => {
  // const [user, setUser] = useState(props.system.selectedClient.id)

  /*
  const curr = () => {
    if (localStorage.getItem('curr') === null) {
      localStorage.setItem('curr', `${props.system.selectedClient.id}`);
    }
    return Number(localStorage.getItem('curr'));
  }

  This is to help with the props.system.selectedClient.id issue. Comment it back in and replace at the necessary places
  to help the code work better.
    return (
    <Suppression clientId={curr} suppressions={props.suppressions.list} />
  )
  */

  useEffect(() => {
    props.loadSuppressions();
  }, []);


  return (
    <Suppression clientId={props.system.selectedClient.id} suppressions={props.suppressions.list} />
  )
    ;
}

const mapStateToProps = (state: AppState) => ({
  clients: state.clients,
  scripts: state.scripts,
  system: state.system,
  suppressions: state.suppressions
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadClients: () => dispatch(loadClients()),
    loadSuppressions: () => dispatch(loadSuppressions())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Suppressions)
);
