import {
    CHANGE_ACCOUNT,
    Integration,
    LOAD_INTEGRATIONS,
    LOAD_INTEGRATIONS_SUCCESS,
    SAVE_INTEGRATION,
    SAVE_INTEGRATION_PENDING,
    SAVE_INTEGRATION_SUCCESS,
    CLEAR_MESSAGE
} from "./types";
import store from '..';
import axios from '../../lib/axios';
import config from '../../config/app-config.json';

export const changeAccountId = (value) => {
    return (dispatch: any) => {
        dispatch({
            type: CHANGE_ACCOUNT,
            payload: value
        })
    }
}

export const loadIntegrations = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_INTEGRATIONS,
            payload: null
        })
        axios.get(`${config.serviceUrl}/integrations/`).then((response) => {
            dispatch({
                type: LOAD_INTEGRATIONS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const saveIntegration = (integration: any, history: any) => {
    return (dispatch: any) => {
        dispatch({
            type: SAVE_INTEGRATION,
            payload: null
        })
        axios.post(`${config.serviceUrl}/integrations`, integration).then((response: any) => {
            console.log(response);
            if (response.data.data.accounts) {
                dispatch({
                    type: SAVE_INTEGRATION_PENDING,
                    payload: response
                })
            } else {
                dispatch({
                    type: SAVE_INTEGRATION_SUCCESS,
                    payload: response
                })
            }
        })
    }
}
export const reSaveIntegration = (accountId: any) => {
    return (dispatch: any) => {
        dispatch({
            type: SAVE_INTEGRATION,
            payload: null
        })
        axios.post(`${config.serviceUrl}/integrations`, accountId).then((response: any) => {
            console.log(response);
            dispatch({
                type: SAVE_INTEGRATION_SUCCESS,
                payload: response
            })
        }).catch((err: any) => {
            dispatch({
                type: SAVE_INTEGRATION_SUCCESS,
                payload: err.response.data.error.message
            })
        })

    }
}

export const clearIntegrationMessage = () => {
    return (dispatch: any) => {
        dispatch({
            type: CLEAR_MESSAGE,
            payload: null
        })
    }
}
