import React, { Component } from "react";
import DropdownComponent from "../../components/dropdown";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { TriggersState } from "../../store/triggers/types";
import { styled, withStyles } from "@mui/material/styles";
import { containsObject, removeDuplicateObjects } from "../../lib/common";
import "./triggers.css";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";

import { ScriptsState } from "../../store/scripts/types"
import { DestinationsState } from "../../store/destinations/types";
import { loadScriptById } from "../../store/scripts/actions"

import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { RuleGroupType, formatQuery } from 'react-querybuilder';
import { QueryBuilder, defaultOperators } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { AnyAction } from "redux";
const styles = (theme) => ({
  checked: {
    color: "#194BFB",
    "font-weight": "600",
  },
  colorSecondary: {
    color: "#194BFB !important",
  },
});

interface AddNewTriggerCardInterfaceProps {
  triggers: TriggersState;
  emptyTrigger: Function;
  updateTriggerField: Function;
  classes: any;
  destinations: any;
  deleteDestination: Function;
  testDestination: any;
  deleteTrigger: Function;
  saveTrigger: any;
  history?: any;
  clientId: any;
  addNewTriggerContainer: any;
  emptyDestination: any;
  updateDestinationField: any;
  edittedDestination: any;
  saveDestination: any;
  scriptId: any;
  scripts: ScriptsState;
  destination: DestinationsState;
}

let operators: Array<any> = [];
for (let i = 0; i < defaultOperators.length; i++) {
  if (defaultOperators[i].name !== 'in' && defaultOperators[i].name !== 'notIn' && defaultOperators[i].name !== 'notIn' && defaultOperators[i].name !== 'between' && defaultOperators[i].name !== 'notBetween') {
    operators.push(defaultOperators[i]);
  }
}


const parameters = [
  { name: "custom1", label: "Custom1" },
  { name: "custom2", label: "Custom2" },
  { name: "custom3", label: "Custom3" },
  { name: "custom4", label: "Custom4" },
  { name: "custom5", label: "Custom5" },
  { name: "custom6", label: "Custom6" },
  { name: "custom7", label: "Custom7" },
  { name: "custom8", label: "Custom8" },
  { name: "custom9", label: "Custom9" }
];

const options = {
  email: 'Email Address',
  hashEmail: 'Hash Email',
  timestamp: 'Timestamp of Visit',
  sourceId: 'Source ID',
  clckId: 'Click ID',
  custom1: 'Custom + 1-10',

};

const fields = [
  {
    name: "email",
    label: "Email",
    operators: operators
  },
  {
    name: "url",
    label: "URL",
    operators: operators
  },
  /*{
    name: "urlParam",
    label: "URL Parameter",
    operators: operators
  },*/
  /*{
    name: "landingDomain",
    label: "Domain",
    operators: operators
  },*/
  {
    name: "country",
    label: "Country",
    operators: operators
  },
  {
    name: "state",
    label: "State",
    operators: operators
  },
  {
    name: "device",
    label: "Device",
    operators: operators
  },
  {
    name: "zid",
    label: "zid",
    operators: operators
  },
  {
    name: "pid",
    label: "pid",
    operators: operators
  },
  {
    name: "cid",
    label: "cid",
    operators: operators
  },
  {
    label: "Custom 1",
    name: "custom1",
    operators: operators
  },
  {
    label: "Custom 2",
    name: "custom2",
    operators: operators
  },
  {
    label: "Custom 3",
    name: "custom3",
    operators: operators
  },
  {
    label: "Custom 4",
    name: "custom4",
    operators: operators
  },
  {
    label: "Custom 5",
    name: "custom5",
    operators: operators
  },
  {
    label: "Custom 6",
    name: "custom6",
    operators: operators
  },
  {
    label: "Custom 7",
    name: "custom7",
    operators: operators
  },
  {
    label: "Custom 8",
    name: "custom8",
    operators: operators
  },
  {
    label: "Custom 9",
    name: "custom9",
    operators: operators
  }
  /*,
  {
    name: "timeOnPage",
    label: "Time on Page (seconds)",
    operators: defaultOperators.filter((op) => op.name === '>' || op.name === '<'),
    inputType: 'number'
  }*/
];

const condition_options = [
  { name: "Contains", value: "Contains" },
  { name: "Does not Contain", value: "Does not Contain" },
  { name: "Exactly Matches", value: "Exactly Matches" },
  { name: "Does not Exactly Match", value: "Does not Exactly Match" },
  { name: "Starts With", value: "Starts With" },
  { name: "Does not Start With", value: "Does not Start With" },
  { name: "Ends With", value: "Ends With" },
  { name: "Does not End With", value: "Does not End With" },
  { name: "Greater Than", value: "Greater Than" },
  { name: "Less Than", value: "Less Than" },
  { name: "After", value: "After" },
  { name: "Before", value: "Before" },
  { name: "Equals", value: "Equals" },
  { name: "Exists", value: "Exists" },
  { name: "Does not Exist", value: "Does not Exist" },
];

const type_options = [{ name: "webhook", value: "webhook" }];

const methods = [
  { name: "GET", value: "GET" },
  { name: "POST", value: "POST" },
];

const date = new Date();
var endDate = new Date();
endDate.setDate(endDate.getDate() + 30);

type MyState = {
  selectedCondition: string;
  isEdit: boolean;
  destinations: any;
  selectedDestinations: any;
  openAddNewOptionsModal: boolean;
  testingTrigger: boolean;
  testResults: any;
  triggerName: string;
  scriptId: any;
  startDate: string;
  endDate: string;
  optionObject: any;
  filterOptions: any;
  dynamicData: any;
  clickedOptions: any;
};

class AddNewTriggerCard extends Component<AddNewTriggerCardInterfaceProps, MyState> {
  state: MyState = {
    // optional second annotation for better type inference
    selectedCondition: "text",
    isEdit: true,
    destinations: this.props.destinations,
    selectedDestinations: [],
    openAddNewOptionsModal: false,
    testingTrigger: false,
    testResults: {},
    triggerName: "",
    scriptId: this.props.scriptId,
    startDate: date.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
    optionObject: {
      name: "",
      type: "",
      url: "",
      headers: "",
      method: "",
      emailRename: "",
    },
    filterOptions: "",
    dynamicData: {},
    clickedOptions: []
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.editTrigger = this.editTrigger.bind(this);
    this.handleChangeDestination = this.handleChangeDestination.bind(this);
    this.handleChangeAddOption = this.handleChangeAddOption.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.inputForFilter = this.inputForFilter.bind(this);
    this.saveTriggerData = this.saveTriggerData.bind(this);
    this.addExtraFilter = this.addExtraFilter.bind(this);
    this.deleteDestination = this.deleteDestination.bind(this);
    this.testDestination = this.testDestination.bind(this);
  }

  public componentDidMount(): void {
    this.props.updateTriggerField("clientId", this.props.clientId);
    this.props.updateTriggerField("scriptId", this.props.scriptId);
  }

  public componentDidUpdate(
    prevProps: Readonly<AddNewTriggerCardInterfaceProps>,
    prevState: Readonly<MyState>,
    snapshot?: any
  ): void {
    if (this.props.destinations !== prevProps.destinations) {
      this.setState({ destinations: this.props.destinations });
    }
  }

  public copy(e, key) {
    e.preventDefault();
    const tag = '{{' + key + '}}';
    const el: any = document.activeElement;
  if (el.nodeName === 'INPUT' || el.nodeName === 'TEXTAREA') {
    const startPos = el.selectionStart;
    const endPos = el.selectionEnd;
    const newCursorPos = startPos + tag.length;

    el.value =
      el.value.substring(0, startPos) +
      tag +
      el.value.substring(endPos);
    el.setSelectionRange(newCursorPos, newCursorPos);

    this.handleChangeAddOption('url', el.value);
  }
  }

  public edit(id): void { }

  public getTrigger() {
    return 1;
  }

  private inputForFilter(condition: string) {
    let type = 'text';
    if (condition == "Greater Than" || condition == "Less Than") {
      type = 'number'
    } else if (
      condition == "After" ||
      condition == "Before" ||
      condition == "Equals"
    ) {
      type = "date";;
    } else {
      type = "text";;
    }
    return type
  }

  private setQuery(query) {
    this.handleChange("filterOptions", formatQuery(query, 'json'));
    this.setState({ filterOptions: query });
  }

  private setDynamicData(field, value) {
    var dynamic = this.state.dynamicData;
    dynamic[field] = value;
    this.handleChange("dynamicData", JSON.stringify(dynamic));
    this.setState({ dynamicData: dynamic });
  }

  private handleChange(field: string, value: any, type?: any): void {
    let modifiedValue = value;
    if (field == "condition") {
      if (value.value == "Greater Than" || value.value == "Less Than") {
        this.setState({ selectedCondition: "number" });
      } else if (
        value.value == "After" ||
        value.value == "Before" ||
        value.value == "Equals"
      ) {
        this.setState({ selectedCondition: "date" });
      } else {
        this.setState({ selectedCondition: "text" });
      }
      return;
    } else if (type == "dropdown") {
      modifiedValue = value.value;
    }
    if (field == "name") {
      this.setState({ triggerName: modifiedValue });
    }
    this.props.updateTriggerField(field, modifiedValue);
  }

  private noEndChange(e): void {
    if (this.props.triggers.editedTrigger.endDate.split('T')[0] != '2200-01-01') {
      this.handleChange('endDate', '2200-01-01');
    } else {
      this.handleChange('endDate', endDate.toISOString().split('T')[0]);
    }
  }

  public editTrigger = (event: boolean) => {
    this.setState({ isEdit: event });
  };

  private handleChangeFilters(name: string, value: any, index: number) {
    let filters: any = this.state.filterOptions.filters
    if (name == 'filter_join') {
      filters[index] = value.value || value;
    } else {
      filters[index][name] = value.value || value;
    }
    this.handleChange("filterOptions", JSON.stringify({ filters: filters }))
  }

  public handleChangeDestination = (event, type) => {
    if (event.target.value.includes("NEW")) {
      this.setState({ openAddNewOptionsModal: true });
      this.handleChangeAddOption("clientId", this.props.clientId)
    } else {
      let selectedDestinations = removeDuplicateObjects(event.target.value, "id");
      this.setState({
        selectedDestinations: selectedDestinations,
      });
      this.props.updateTriggerField("destinationIds", selectedDestinations && selectedDestinations.length ? selectedDestinations.map((item: any) => item.id).join(',') : '');
    }
  };

  public editDestination(id, destination) {
    this.setState({ openAddNewOptionsModal: true });
    let optionObject = { ...destination };
    this.setState({ optionObject: optionObject });
    this.props.updateDestinationField('clientId', destination.clientId);
    this.props.updateDestinationField('id', id);
    this.props.updateDestinationField('name', destination.name);
    this.props.updateDestinationField('type', destination.type);
    this.props.updateDestinationField('method', destination.method);
    this.props.updateDestinationField('url', destination.url);
    this.props.updateDestinationField('emailRename', destination.emailRename);
    this.props.updateDestinationField('body', destination.body);
    this.props.updateDestinationField('headers', destination.headers);
    this.props.updateDestinationField('apiKey', destination.apiKey);
    this.props.updateDestinationField('customProperties', destination.customProperties);

  }

  public deleteDestination() {
    this.props.deleteDestination(this.props.edittedDestination.id);
    this.setState({ openAddNewOptionsModal: false });
  }

  public testDestination() {
    this.props.testDestination(this.props.edittedDestination);
    this.setState({ testingTrigger: true });
  }

  public closeTest() {
    this.setState({ testingTrigger: false });
  }

  public handleChangeAddOption = (name, value) => {
    let optionObject = { ...this.state.optionObject };
    optionObject[name] = value;
    this.setState({ optionObject: optionObject });
    this.props.updateDestinationField(name, value);
  };


  public saveOption = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Save option called");
    this.props.saveDestination(this.props.edittedDestination, this.props.history);
    console.log("Save option finished");
  };

  public saveTriggerData() {
    this.editTrigger(false);
    this.props.addNewTriggerContainer(false)
    this.props.saveTrigger(this.props.triggers.editedTrigger, this.props.history);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  private addExtraFilter() {
    let filters: any = [...this.state.filterOptions.filters]
    filters.push(...['AND', {
      "field": "",
      "operator": "",
      "value": ""
      ,
    }]);
    this.setState({ filterOptions: { filters: filters } })
  }

  public render(): JSX.Element {
    const state = this.state;
    const props = this.props;
    const { classes } = this.props;
    let filterOptions = state.filterOptions.filters;

    return (
      <>
        {state.openAddNewOptionsModal ? (
          <Dialog
            fullWidth
            aria-labelledby="customized-dialog-title"
            open={state.openAddNewOptionsModal}
            id="customize-dialog-box"
          >
            <div className="customized-dialog-header">
              <DialogTitle id="customized-dialog-title">
                {this.props.edittedDestination.id ? "Edit Option" : "Add New Option"}
              </DialogTitle>
              <div>
                {this.props.edittedDestination.id ?
                  <button id="test-btn" onClick={() => this.testDestination()}>Test</button>
                  : <></>}
                {this.props.edittedDestination.id ?
                  <button onClick={() => this.deleteDestination()}>Delete</button>
                  : <></>}
              </div>
            </div>
            <DialogContent dividers>
              {state.testingTrigger ? (
                <Dialog
                  fullWidth
                  aria-labelledby="customized-dialog-title"
                  open={state.testingTrigger}
                >{JSON.stringify(this.props.destination.testResults)} <button onClick={() => this.closeTest()}>Done</button> </Dialog>) : <></>}
              <form
                // className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={(event) => this.saveOption(event)}
              >
                <div className="flexBox">
                  <div id="w50" className="mb-4">
                    <label className="field_label">Name</label>
                    <input
                      name="name"
                      required
                      type="text"
                      value={props.edittedDestination.name || ''}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.handleChangeAddOption("name", event.target.value)
                      }
                    />
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Type</label>
                    <div className="select_multi">
                      <Select
                        name="type"
                        value={props.edittedDestination.type || ''}
                        onChange={(event) =>
                          this.handleChangeAddOption("type", event.target.value)
                        }
                        variant="outlined"
                      >
                        {type_options.map((option) => (
                          <MenuItem key={option.name} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div id="w100" className="mb-4">
                    <label className="field_label">URL</label>
                    <input
                      name="url"
                      required
                      type="text"
                      value={this.state.optionObject.url || ''}
                      //value="305-000-0000"
                      onChange={(event) => {
                        this.handleChangeAddOption("url", event.target.value)
                      }
                      }
                      disabled={!state.isEdit}
                    />
                    <div className="options_buttons_area">
                      {Object.keys(options).map(k => (
                        <button key={k} className="options_button" onClick={e => {
                          const clickedOption = options[k];
                          const updatedClickedOptions = [...this.state.clickedOptions, clickedOption];

                          this.setState({ clickedOptions: updatedClickedOptions });
                          this.copy(e, k);
                          //this.handleChangeAddOption("url", `${this.state.optionObject.url}{{${options[k]}}}`)
                        }}>
                          {options[k]}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Headers</label>
                    <input
                      name="headers"
                      required
                      type="text"
                      value={props.edittedDestination.headers || ''}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.handleChangeAddOption(
                          "headers",
                          event.target.value
                        )
                      }
                      disabled={!state.isEdit}
                    />
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Method</label>
                    <div className="select_multi">
                      <Select
                        name="method"
                        value={props.edittedDestination.method || 'GET'}
                        onChange={(event) =>
                          this.handleChangeAddOption(
                            "method",
                            event.target.value
                          )
                        }
                        variant="outlined"
                      >
                        {methods.map((option) => (
                          <MenuItem key={option.name} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Body</label>
                    <input
                      name="body"
                      required
                      type="text"
                      value={props.edittedDestination.body || ''}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.handleChangeAddOption("body", event.target.value)
                      }
                    />
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Custom Properties</label>
                    <input
                      name="customProperties"
                      required
                      type="text"
                      value={props.edittedDestination.customProperties}
                      onChange={(event) =>
                        this.handleChangeAddOption("customProperties", event.target.value)
                      }
                    />
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <button
                className="button secondary mr-3"
                onClick={() => this.setState({ openAddNewOptionsModal: false })}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button accent"
                onClick={(event) => {
                  this.setState({ openAddNewOptionsModal: false });
                  this.saveOption(event);
                }}
              >
                Save Changes
              </button>
            </DialogActions>
          </Dialog>
        ) : null}
        <div className="trigger_card">
          <div className="d-flex trigger_card__heading mb-4">
            {state.isEdit ? (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">Name</label>
                <input
                  required
                  type="text"
                  value={props.triggers.editedTrigger.name}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("name", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : (
              <h3>{state.triggerName || "Trigger Name Example"}</h3>
            )}

            {state.isEdit ? (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">Start Date</label>
                <input
                  required
                  type="date"
                  value={props.triggers.editedTrigger.startDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("startDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">Start Date</label>
                <input
                  required
                  type="date"
                  value={props.triggers.editedTrigger.startDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("startDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            )}

            {state.isEdit ? (
              <div className="d-flex">
                <label className="field_label mr-4 mb-0">No End?</label>
                <input
                  required
                  type="checkbox"
                  checked={props.triggers.editedTrigger.endDate.split('T')[0] == '2200-01-01'}
                  //value="305-000-0000"
                  onChange={(e) =>
                    this.noEndChange("noEnd")
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : (
              <div className="d-flex">
                <label className="field_label mr-4 mb-0">No End?</label>
                <input
                  required
                  type="checkbox"
                  checked={props.triggers.editedTrigger.endDate.split('T')[0] == '2200-01-01'}
                  //value="305-000-0000"
                  onChange={(e) =>
                    this.noEndChange("noEnd")
                  }
                  disabled={!state.isEdit}
                />
              </div>
            )}

            {state.isEdit && props.triggers.editedTrigger.endDate.split('T')[0] != '2200-01-01' ? (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">End Date</label>
                <input
                  required
                  type="date"
                  value={props.triggers.editedTrigger.endDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("endDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : props.triggers.editedTrigger.endDate.split('T')[0] != '2200-01-01' ? (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">End Date</label>
                <input
                  required
                  type="date"
                  value={props.triggers.editedTrigger.endDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("endDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : ''}

            {state.isEdit ? (
              <div>
                <button
                  className="button secondary mr-3"
                  onClick={() => { this.editTrigger(false); this.props.addNewTriggerContainer(false) }}
                >
                  Cancel
                </button>
                <button
                  className="button accent"
                  onClick={() => this.saveTriggerData()}
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                className="button secondary"
                onClick={() => {
                  this.editTrigger(true);
                }}
              >
                Edit Trigger
              </button>
            )}
          </div>

          {
            (
              <div>
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                  <QueryBuilder fields={fields} query={this.state.filterOptions} onQueryChange={q => this.setQuery(q)} />
                </QueryBuilderDnD>
                <div className="pt15 time_on_page">
                  <div id="w50" className="d-flex">
                    <label className="field_label mr-4 mb-0">Time On Page In Seconds</label>
                    <input
                      required
                      type="number"
                      value={this.state.dynamicData.timeOnPage}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.setDynamicData("timeOnPage", event.target.value)
                      }
                      disabled={!state.isEdit}
                    />
                  </div>
                </div>
                <div className="pt15">
                  <label
                    id="destination-multi-label"
                    className="field_label"
                  >
                    Destination
                  </label>
                  <div className="select_multi">
                    <Select
                      labelId="destination-multi-label"
                      id="destination-multi"
                      multiple
                      value={state.selectedDestinations || []}
                      onChange={this.handleChangeDestination}
                      // renderValue={(selected) => (selected as string[]).join(', ')}
                      renderValue={(selected) =>
                        Array.from((selected) as any)
                          .map((item: any) => item.name)
                          .join(", ")
                      }
                      variant="outlined"
                      disabled={!state.isEdit}
                      name="destination-multi"
                    >
                      <MenuItem
                        value="NEW"
                        // classes={{ root: classes.checked }}
                        onClick={(e) => { this.props.emptyDestination() }}
                      >
                        <AddIcon
                          fontSize="small"
                          style={{
                            marginRight: "1rem",
                            marginLeft: "0.5rem",
                          }}
                        />
                        Add New
                      </MenuItem>
                      {state.destinations.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          <Checkbox
                            // classes={{
                            //   checked: classes.checked,
                            //   colorSecondary: classes.colorSecondary,
                            // }}

                            checked={containsObject(
                              option,
                              state.selectedDestinations
                            )}
                          />
                          <ListItemText primary={option.name} />
                          <ListItemIcon>
                            <EditIcon onClick={(e) => { this.editDestination(option.id, option) }} />
                          </ListItemIcon>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )
          }

        </div>
      </>
    );
  }
}

//export default withStyles(styles, { withTheme: true })(AddNewTriggerCard);
export default styled(AddNewTriggerCard)(({ theme }) => (styles));
