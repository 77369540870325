import {
    LOAD_REPORTS,
    LOAD_REPORTS_SUCCESS,
    SORT_REPORTS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_STATE,
} from "./types";
import axios from '../../lib/axios';
import config from '../../config/app-config.json';

export const loadReport = (filterByDate, filterByDateType, scriptId) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOAD_REPORTS,
            payload: ''
        })

        await axios.get(`${config.serviceUrl}/report`, {
            params: {
                    scriptId: scriptId,
                    filterByDateTo: `${filterByDate.to}`,
                    filterByDateFrom: `${filterByDate.from}`,
                    filterByDateType: `${filterByDateType.value}`,
                }
            }).then((response) => {
            dispatch({
                type: LOAD_REPORTS_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const sortReport = col => {
    return dispatch => dispatch({
        type: SORT_REPORTS,
        payload: col
    })
}

export const filterByDate = (value) => {
    return {
        type: FILTER_DATE,
        payload: value
    }
}

export const filterByDateType = (value) => {
    return {
        type: FILTER_DATE_TYPE,
        payload: value
    }
}

export const resetState = () => {
    return dispatch => dispatch({
        type: RESET_STATE,
        payload: []
    })
}
