import { MENU_TOGGLE, CLIENT_MENU_TOGGLE, SELECT_CLIENT, CHECK_ADMIN_RIGHTS } from './types'
import { Session } from '../auth/types';


const parseJwt = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const checkIfIsAdmin = () => {
  let session = localStorage.getItem('session');
  let decodedSession: Session = session === null ? session : JSON.parse(session);
  let groups: Array<string> = [];
  if(decodedSession !== null) {
      let decoded = parseJwt(decodedSession.credentials.accessToken);
      if(decoded['cognito:groups'] && decoded['cognito:groups'].length > 0) {
          groups = decoded['cognito:groups'];
      }
  }


  return groups.indexOf("admin") > -1;
}

const initialState = {
  menuVisible: false,
  isAdmin: checkIfIsAdmin(),
  clientsExpanded: false,
  selectedClient: null
}

const systemReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MENU_TOGGLE:
      return Object.assign({}, {
        ...state,
        menuVisible: !state.menuVisible
      })

    case CLIENT_MENU_TOGGLE:
      return Object.assign({}, {
        ...state,
        clientsExpanded: !state.clientsExpanded
      })
    case SELECT_CLIENT:
      console.log(action);
      return {
        ...state,
        clientsExpanded: false,
        selectedClient: action.payload
      }
    case CHECK_ADMIN_RIGHTS:
      return {
        ...state,
        isAdmin: checkIfIsAdmin()
      }
    default:
      return state
  }
}

export default systemReducer