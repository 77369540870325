export const LOAD_TRIGGERS = "LOAD_TRIGGERS";
export const LOAD_TRIGGERS_SUCCESS = "LOAD_TRIGGERS_SUCCESS";
export const UPDATE_TRIGGER_FIELD = "UPDATE_TRIGGER_FIELD";
export const LOAD_TRIGGER_BY_ID = "LOAD_TRIGGER_BY_ID";
export const LOAD_TRIGGER_BY_ID_SUCCESS = "LOAD_TRIGGER_BY_ID_SUCCESS";
export const CREATE_TRIGGER_SUCCESS = "CREATE_TRIGGER_SUCCESS";
export const UPDATE_TRIGGER_SUCCESS = "UPDATE_TRIGGER_SUCCESS";
export const REMOVE_TRIGGER_BY_ID = "REMOVE_TRIGGER_BY_ID";
export const REMOVE_TRIGGER_BY_ID_SUCCESS = "REMOVE_TRIGGER_BY_ID_SUCCESS";
export const SORT_TRIGGERS = "SORT_TRIGGERS";
export const SAVE_TRIGGER = "SAVE_TRIGGER";
export const DELETE_TRIGGER = "DELETE_TRIGGER";
export const DELETE_TRIGGER_SUCCESS = "DELETE_TRIGGER_SUCCESS";

export interface TriggersState {
    triggers: Array<Trigger>
    triggersSortOptions: {
        field: string
        direction: boolean
    }
    editedTrigger: Trigger
    loading: boolean
    loadingTrigger: boolean
}

export interface Trigger {
    id?: number
    clientId: number
    name: string
    destinationIds: number
    description: string
    status: string
    filterOptions: any
    startDate: string
    endDate: string
    dateCreated: string
    dateUpdated: string
    scriptId: number
    dynamicData: any
}

interface DeleteTrigger {
    type: typeof DELETE_TRIGGER_SUCCESS | typeof DELETE_TRIGGER,
    payload: any
}

interface LoadTriggers {
    type: typeof LOAD_TRIGGERS_SUCCESS | typeof LOAD_TRIGGERS,
    payload: Array<Trigger>
}

interface LoadTriggerById {
    type: typeof LOAD_TRIGGER_BY_ID_SUCCESS | typeof LOAD_TRIGGER_BY_ID,
    payload: Trigger
}

interface UpdateTriggerField {
    type: typeof UPDATE_TRIGGER_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateTrigger {
    type: typeof UPDATE_TRIGGER_SUCCESS | typeof SAVE_TRIGGER,
    payload: any
}

interface CreateTrigger {
    type: typeof CREATE_TRIGGER_SUCCESS | typeof SAVE_TRIGGER,
    payload: any
}

interface RemoveTrigger {
    type: typeof REMOVE_TRIGGER_BY_ID_SUCCESS
    payload: any
}

interface SortTriggers {
    type: typeof SORT_TRIGGERS,
    payload: any
}

interface DefaultAction {
    type: any
    payload?: any
}

export type TriggerActionTypes = LoadTriggers | UpdateTriggerField | LoadTriggerById | LoadTriggers | CreateTrigger | UpdateTrigger | RemoveTrigger | DefaultAction | SortTriggers | DeleteTrigger;
