import React from 'react';
import './header.css';
import { NavLink as Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { menuToggle, toggleClientMenu, selectClient } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { AppState } from '../../store';
import { filterClients } from '../../store/clients/actions';
import { ClientsState } from '../../store/clients/types';
import { ReactComponent as AccountIcon } from '../../icons/account.svg';

interface AppHeaderInterface {
    clients: ClientsState
    system: SystemState
    logout: typeof logout
    menuToggle: typeof menuToggle
    toggleClientMenu: typeof toggleClientMenu
    selectClient: typeof selectClient
    filterClients: typeof filterClients
}

const mapDispatchToProps = dispatch => {
    return {
      logout: () => dispatch(logout() ),
      menuToggle: () => dispatch(menuToggle() ),
      toggleClientMenu: () => dispatch(toggleClientMenu() ),
      selectClient: (client, history) => dispatch(selectClient(client, history) ),
      filterClients: e => dispatch(filterClients(e.target.value) )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    clients: state.clients
})

class AppHeader extends React.Component<AppHeaderInterface & RouteComponentProps> {
    componentDidMount() {

    }

    toggleClientMenu() {
        this.props.toggleClientMenu();
        this.props.filterClients({target: {value: ''}});
    }

    render() {
        console.log(this);
        return (
            <div className="app__header">
                {(this.props.system.isAdmin) ?
                    <div className={`client__switch ${this.props.system.clientsExpanded ? 'expanded' : ''}`} >
                        <button onClick={this.toggleClientMenu.bind(this)} className={`client__select ${this.props.system.clientsExpanded ? 'close' : ''}`}>{this.props.system.selectedClient !== null ? this.props.system.selectedClient.clientName : ''}<span className={this.props.system.clientsExpanded ? 'expanded' : ''}> &lsaquo;</span></button>
                        <button onClick={this.toggleClientMenu.bind(this)} className={`client__select-icon ${this.props.system.clientsExpanded ? 'open' : 'close'}`}>{this.props.system.clientsExpanded ? "X" : <AccountIcon height="12" />}</button><br/>
                        <div className={`client__list ${this.props.system.clientsExpanded ? 'expanded' : ''}`}>
                            <input type="text" onChange={this.props.filterClients} placeholder="Client search..." />
                            <ul>
                                {this.props.clients.clientsFiltered.map(client =>
                                    <li onClick={() => this.props.selectClient(client, this.props)} key={`acc_${client.id}`}>
                                        {client.clientName} - {client.clientEmail}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                : null}
                <button className="sign__out" onClick={() => this.props.logout()}>Sign Out</button>
            </div>
        )
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
