import React, { Component } from "react";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { withRouter } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { NavLink as Link } from "react-router-dom";
import { TriggersState } from "../../store/triggers/types";
import AppHeader from "../../components/header/header";

import {
  emptyTrigger,
  loadTriggerById2,
  loadTriggers,
  updateTriggerField,
  saveTrigger,
  deleteTrigger,
} from "../../store/triggers/actions";
import {
  loadDestinations,
  emptyDestination,
  updateDestinationField,
  saveDestination,
  deleteDestination,
  testDestination,
} from "../../store/destinations/actions";
import { loadClientById, loadClients, loadClientsForAPI } from "../../store/clients/actions"
import "./triggers.css";
import TriggerCard from "./triggerCard";
import AddNewTriggerCard from "./addTrigger";
import { DestinationsState } from "../../store/destinations/types";
import Spinner from "../../components/spinner";
import { SystemState } from "../../store/system/types";
import {ScriptsState} from "../../store/scripts/types"
import {loadScriptById} from "../../store/scripts/actions"


interface TriggerInterfaceProps {
  triggers: TriggersState;
  system: SystemState;
  emptyTrigger: typeof emptyTrigger;
  updateTriggerField: typeof updateTriggerField;
  testDestination: typeof testDestination;
  deleteDestination: typeof deleteDestination;
  deleteTrigger: typeof deleteTrigger;
  loadClients: typeof loadClients;
  loadClientsById: typeof loadClientById;
  loadTriggers: typeof loadTriggers;
  loadDestinations: typeof loadDestinations;
  loadTriggerById2: typeof loadTriggerById2;
  saveTrigger: typeof saveTrigger;
  emptyDestination: typeof emptyDestination;
  updateDestinationField: typeof updateDestinationField;
  destinations: DestinationsState;
  saveDestination: typeof saveDestination;
  loadScriptById: typeof loadScriptById;
  history?: any;
  loadingTriggers: boolean;
  loadingDestination: boolean;
  scripts: ScriptsState;
}

type MyState = {
  addNewTrigger: boolean;
  clientId: number;
};

class Trigger extends React.Component<TriggerInterfaceProps, MyState> {
  constructor(props) {
    super(props);
    this.addNewTriggerContainer = this.addNewTriggerContainer.bind(this);
  }

  state: MyState = {
    // optional second annotation for better type inference
    addNewTrigger: false,
    clientId: 0,
  };

  public componentDidMount(): void {
    /*loadClientsForAPI().then((res) => {
      if (res.data && res.data.data && res.data.data.length && res.data.data[0]) {
        this.setState({ clientId: res.data.data[0].id })
        this.props.loadTriggers(res.data.data[0].id);
        this.props.loadDestinations(res.data.data[0].id);
      }
    })*/

    this.props.loadTriggers();
    this.props.loadDestinations();
  }

  public addNewTriggerContainer(type: boolean) {
    this.props.emptyTrigger();
    this.setState({ addNewTrigger: type });
  }

  public render(): JSX.Element {
    const props = this.props;
    const state = this.state;
    console.log(this);
    return (
      <div style={{ height: `calc(100vh - 6rem)` }}>
        <AppHeader></AppHeader>
        <div className="trigger_section_layout">
          <div className="d-flex trigger_section">
            <input
              type="search"
              id="query"
              name="q"
              placeholder="Search..."
              aria-label="Search through site content"
            />
            <button
              onClick={() => this.addNewTriggerContainer(true)}
              className="button accent"
            >
              + Add New
            </button>
          </div>

          {props.loadingTriggers ? (
            <Spinner />
          ) : (
            <div>
              {state.addNewTrigger ? (
                <AddNewTriggerCard
                  emptyTrigger={props.emptyTrigger}
                  deleteDestination={props.deleteDestination}
                  updateTriggerField={props.updateTriggerField}
                  destinations={props.destinations.destinations}
                  triggers={props.triggers}
                  saveTrigger={props.saveTrigger}
                  history={props.history}
                  emptyDestination={props.emptyDestination}
                  updateDestinationField={props.updateDestinationField}
                  edittedDestination={props.destinations.editedDestination}
                  saveDestination={props.saveDestination}
                  clientId={props.system.selectedClient.id}
                  addNewTriggerContainer={this.addNewTriggerContainer}
                  scriptId={props.scripts.editedScript.id}
                  testDestination={props.testDestination}
                  destination={props.destinations}
                />
              ) : null}

              {props.triggers.triggers.length
                ? props.triggers.triggers.map((item) => (
                  <TriggerCard
                    trigger={item}
                    emptyTrigger={props.emptyTrigger}
                    deleteDestination={props.deleteDestination}
                    deleteTrigger={props.deleteTrigger}
                    updateTriggerField={props.updateTriggerField}
                    destinations={props.destinations.destinations}
                    loadTriggerById2={props.loadTriggerById2}
                    triggers={props.triggers}
                    saveTrigger={props.saveTrigger}
                    history={props.history}
                    emptyDestination={props.emptyDestination}
                    updateDestinationField={props.updateDestinationField}
                    edittedDestination={props.destinations.editedDestination}
                    saveDestination={props.saveDestination}
                    clientId={item.clientId}
                    testDestination={props.testDestination}
                    destination={props.destinations}
                    loadTriggers={props.loadTriggers}
                  />
                ))
                : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  triggers: state.triggers,
  destinations: state.destinations,
  loadingTriggers: state.triggers.loading,
  loadingDestination: state.destinations.loading,
  system: state.system,
  scripts: state.scripts
});

const mapDispatchToProps = (dispatch) => {
  return {
    emptyTrigger: () => dispatch(emptyTrigger()),
    updateTriggerField: (field, value) =>
      dispatch(updateTriggerField(field, value)),
    loadClients: () => dispatch(loadClients()),
    loadClientById: id => dispatch(loadClientById(id)),
    loadTriggers: () => dispatch(loadTriggers()),
    loadDestinations: () => dispatch(loadDestinations()),
    loadTriggerById2: (data?) => dispatch(loadTriggerById2(data)),
    saveTrigger: (data?, history?) => dispatch(saveTrigger(data, history)),
    emptyDestination: () => dispatch(emptyDestination()),
    updateDestinationField: (field, value) =>
      dispatch(updateDestinationField(field, value)),
    saveDestination: (data?, history?) =>
      dispatch(saveDestination(data, history)),
      loadScriptById: id => dispatch(loadScriptById(id)),
      deleteDestination: id => dispatch(deleteDestination(id)),
      deleteTrigger: id => dispatch(deleteTrigger(id)),
      testDestination: destination => dispatch(testDestination(destination))

    };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Trigger)
);
