import {
    LOAD_SUPPRESSIONS,
    LOAD_SUPPRESSIONS_SUCCESS,
    SORT_SUPPRESSIONS,
    FILTER_DATE,
    FILTER_DATE_TYPE,
    RESET_STATE,
} from "./types";
import axios from '../../lib/axios';
import config from '../../config/app-config.json';

export const loadSuppressions = () => {
    return async (dispatch: any) => {
        dispatch({
            type: LOAD_SUPPRESSIONS,
            payload: []
        })

        await axios.get(`${config.serviceUrl}/suppression`).then((response) => {
            console.log(response);
            dispatch({
                type: LOAD_SUPPRESSIONS_SUCCESS,
                payload: response.data
            })
        })
    }
}

export const sortSuppressions = col => {
    return dispatch => dispatch({
        type: SORT_SUPPRESSIONS,
        payload: col
    })
}

export const filterByDate = (value) => {
    return {
        type: FILTER_DATE,
        payload: value
    }
}

export const filterByDateType = (value) => {
    return {
        type: FILTER_DATE_TYPE,
        payload: value
    }
}

export const resetState = () => {
    return dispatch => dispatch({
        type: RESET_STATE,
        payload: []
    })
}
