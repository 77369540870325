import {
    DestinationsState,
    LOAD_DESTINATIONS,
    LOAD_DESTINATIONS_SUCCESS,
    UPDATE_DESTINATION_FIELD,
    LOAD_DESTINATION_BY_ID,
    LOAD_DESTINATION_BY_ID_SUCCESS,
    CREATE_DESTINATION_SUCCESS,
    UPDATE_DESTINATION_SUCCESS,
    REMOVE_DESTINATION_BY_ID,
    REMOVE_DESTINATION_BY_ID_SUCCESS,
    SORT_DESTINATIONS,
    DestinationActionTypes,
    DELETE_DESTINATION,
    DELETE_DESTINATION_SUCCESS,
    TEST_DESTINATION,
    TEST_DESTINATION_SUCCESS
} from './types';

import { sortByField } from "../../lib/sort";

const emptyDestination: any = {
    name: '',
    type: '',
    method: '',
    url: '',
    emailRename: '',
    body: '{}',
    headers: '',
    apiKey: '',
    customProperties: '',
    clientId:'',
    triggerId: ''
}

const initialSort = {
    field: 'end',
    direction: false
}

const initialState: DestinationsState = {
    loading: false,
    loadingDestination: false,
    testResults: {},
    destinations: [],
    destinationsSortOptions: Object.assign({}, initialSort),
    editedDestination: Object.assign({}, emptyDestination),
};

export function destinationsReducer(
    state = initialState,
    action: DestinationActionTypes
): DestinationsState {
    switch (action.type) {
        case DELETE_DESTINATION:
            return {
                ...state,
                loading: true
            }
        case DELETE_DESTINATION_SUCCESS:
                return {
                    ...state,
                    loading: false
                }
        case LOAD_DESTINATIONS:
            return {
                ...state,
                destinations: [],
                loading: true
            }
        case TEST_DESTINATION:
            return {
                ...state,
                loading: true
            }
        case TEST_DESTINATION_SUCCESS:
            return {
                ...state,
                testResults: action.payload,
                loading: false
            }
        case LOAD_DESTINATIONS_SUCCESS:
            console.log('load success', action);
            return {
                ...state,
                destinations: sortByField(state.destinationsSortOptions.field, state.destinationsSortOptions.direction, action.payload),
                loading: false
            };

        case LOAD_DESTINATION_BY_ID:
            return {
                ...state,
                loadingDestination: true
            };

        case LOAD_DESTINATION_BY_ID_SUCCESS:
            return {
                ...state,
                editedDestination: action.payload,
                loadingDestination: false
            };

        case CREATE_DESTINATION_SUCCESS:
            return {
                ...state,
                editedDestination: Object.assign({}, emptyDestination)
            };

        case UPDATE_DESTINATION_SUCCESS:
            console.log('reducer success');
            return {
                ...state
            };

        case UPDATE_DESTINATION_FIELD:
            console.log('in UPDATE_DESTINATION_FIELD');
            let editedDestination : any = Object.assign({}, state.editedDestination);
            editedDestination[action.payload.field] = action.payload.value;
            return {
                ...state,
                editedDestination
            }

        case SORT_DESTINATIONS:
            state.destinationsSortOptions.direction = !state.destinationsSortOptions.direction;
            state.destinationsSortOptions.field = action.payload.fieldValue;

            return {
                ...state,
                destinations: sortByField(state.destinationsSortOptions.field, state.destinationsSortOptions.direction, state.destinations),
                destinationsSortOptions: Object.assign({}, state.destinationsSortOptions)
            }

        default:
            return state;
    }
}
