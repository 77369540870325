export const LOAD_DESTINATIONS = "LOAD_DESTINATIONS";
export const LOAD_DESTINATIONS_SUCCESS = "LOAD_DESTINATIONS_SUCCESS";
export const UPDATE_DESTINATION_FIELD = "UPDATE_DESTINATION_FIELD";
export const LOAD_DESTINATION_BY_ID = "LOAD_DESTINATION_BY_ID";
export const LOAD_DESTINATION_BY_ID_SUCCESS = "LOAD_DESTINATION_BY_ID_SUCCESS";
export const CREATE_DESTINATION_SUCCESS = "CREATE_DESTINATION_SUCCESS";
export const UPDATE_DESTINATION_SUCCESS = "UPDATE_DESTINATION_SUCCESS";
export const REMOVE_DESTINATION_BY_ID = "REMOVE_DESTINATION_BY_ID";
export const REMOVE_DESTINATION_BY_ID_SUCCESS = "REMOVE_DESTINATION_BY_ID_SUCCESS";
export const SORT_DESTINATIONS = "SORT_DESTINATIONS";
export const DELETE_DESTINATION = "DELETE_DESTINATION";
export const DELETE_DESTINATION_SUCCESS = "DELETE_DESTINATION_SUCCESS";
export const TEST_DESTINATION = "TEST_DESTINATION";
export const TEST_DESTINATION_SUCCESS = "TEST_DESTINATION_SUCCESS";

export interface DestinationsState {
    destinations: Array<Destination>
    destinationsSortOptions: {
        field: string
        direction: boolean
    }
    testResults: any
    editedDestination: Destination
    loading: boolean
    loadingDestination: boolean
}

export interface Destination {
    id?: number
    clientId: number
    name: string
    type: string
    method: string
    url: string
    emailRename: string
    body: string
    headers: string
    apiKey: string
    customProperties: string
    status: string
    failedCount: string
    dateCreated: string
    dateUpdated: string
}

interface DeleteDestination {
    type: typeof DELETE_DESTINATION_SUCCESS | typeof DELETE_DESTINATION,
    payload: any
}

interface LoadDestinations {
    type: typeof LOAD_DESTINATIONS_SUCCESS | typeof LOAD_DESTINATIONS,
    payload: Array<Destination>
}

interface TestDestination {
    type: typeof TEST_DESTINATION | typeof TEST_DESTINATION_SUCCESS,
    payload: any
}

interface LoadDestinationById {
    type: typeof LOAD_DESTINATION_BY_ID_SUCCESS | typeof LOAD_DESTINATION_BY_ID,
    payload: Destination
}

interface UpdateDestinationField {
    type: typeof UPDATE_DESTINATION_FIELD,
    payload: {
        field: string
        value: string
    }
}

interface UpdateDestination {
    type: typeof UPDATE_DESTINATION_SUCCESS
    payload: any
}

interface CreateDestination {
    type: typeof CREATE_DESTINATION_SUCCESS
    payload: any
}

interface RemoveDestination {
    type: typeof REMOVE_DESTINATION_BY_ID_SUCCESS
    payload: any
}

interface SortDestinations {
    type: typeof SORT_DESTINATIONS,
    payload: any
}

interface DefaultAction {
    type: any
    payload?: any
}

export type DestinationActionTypes = LoadDestinations | UpdateDestinationField | LoadDestinationById | LoadDestinations | CreateDestination | UpdateDestination | RemoveDestination | DefaultAction | SortDestinations | DeleteDestination | TestDestination;
