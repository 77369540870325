import React, { Component } from "react";
import DropdownComponent from "../../components/dropdown";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { TriggersState } from "../../store/triggers/types";
import { withStyles, styled } from "@mui/material/styles";
import { containsObject, removeDuplicateObjects } from "../../lib/common";
//import { deleteDestination } from '../../store/destinations/actions';
import "./triggers.css";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { RuleGroupType, formatQuery } from 'react-querybuilder';
import { QueryBuilder, defaultOperators } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';

import './queryBuilderOverride.css';
import { DestinationsState } from "../../store/destinations/types";

const styles = (theme) => ({
  checked: {
    color: "#194BFB",
    "font-weight": "600",
  },
  colorSecondary: {
    color: "#194BFB !important",
  },
});

interface TriggerCardInterfaceProps {
  triggers: TriggersState;
  emptyTrigger: Function;
  deleteDestination: any;
  deleteTrigger: any;
  testDestination: any;
  updateTriggerField: Function;
  classes: any;
  trigger: any;
  destinations: any;
  loadTriggerById2: any;
  saveTrigger: any;
  history?: any;
  emptyDestination: any
  updateDestinationField: any
  edittedDestination: any
  saveDestination: any
  clientId: any
  destination: DestinationsState;
  loadTriggers: Function;
}

let operators: Array<any> = [];
for (let i = 0; i < defaultOperators.length; i++) {
  if (defaultOperators[i].name !== 'in' && defaultOperators[i].name !== 'notIn' && defaultOperators[i].name !== 'notIn' && defaultOperators[i].name !== 'between' && defaultOperators[i].name !== 'notBetween') {
    operators.push(defaultOperators[i]);
  }
}

const parameters = [
  { name: "custom1", label: "Custom1" },
  { name: "custom2", label: "Custom2" },
  { name: "custom3", label: "Custom3" },
  { name: "custom4", label: "Custom4" },
  { name: "custom5", label: "Custom5" },
  { name: "custom6", label: "Custom6" },
  { name: "custom7", label: "Custom7" },
  { name: "custom8", label: "Custom8" },
  { name: "custom9", label: "Custom9" }
];

const options = {
  email: 'Email Address',
  hashEmail: 'Hash Email',
  timestamp: 'Timestamp of Visit',
  sourceId: 'Source ID',
  clckId: 'Click ID',
  custom1: 'Custom + 1-10',
};

const fields = [
  {
    name: "email",
    label: "Email",
    operators: operators
  },
  {
    name: "url",
    label: "URL",
    operators: operators
  },
  /*{
    name: "urlParam",
    label: "URL Parameter",
    operators: operators
  },*/
  /*{
    name: "landingDomain",
    label: "Domain",
    operators: operators
  },*/
  {
    name: "country",
    label: "Country",
    operators: operators
  },
  {
    name: "state",
    label: "State",
    operators: operators
  },
  {
    name: "device",
    label: "Device",
    operators: operators
  },
  {
    name: "zid",
    label: "zid",
    operators: operators
  },
  {
    name: "pid",
    label: "pid",
    operators: operators
  },
  {
    name: "cid",
    label: "cid",
    operators: operators
  },
  {
    label: "Custom 1",
    name: "custom1",
    operators: operators
  },
  {
    label: "Custom 2",
    name: "custom2",
    operators: operators
  },
  {
    label: "Custom 3",
    name: "custom3",
    operators: operators
  },
  {
    label: "Custom 4",
    name: "custom4",
    operators: operators
  },
  {
    label: "Custom 5",
    name: "custom5",
    operators: operators
  },
  {
    label: "Custom 6",
    name: "custom6",
    operators: operators
  },
  {
    label: "Custom 7",
    name: "custom7",
    operators: operators
  },
  {
    label: "Custom 8",
    name: "custom8",
    operators: operators
  },
  {
    label: "Custom 9",
    name: "custom9",
    operators: operators
  }

  /*,
  {
    name: "timeOnPage",
    label: "Time on Page (seconds)",
    operators: defaultOperators.filter((op) => op.name === '>' || op.name === '<'),
    inputType: 'number'
  }*/
];

const condition_options = [
  { name: "Contains", value: "contain" },
  { name: "Does not Contain", value: "notContain" },
  { name: "Exactly Matches", value: "exactMatch" },
  { name: "Does not Exactly Match", value: "notExactMatch" },
  { name: "Starts With", value: "startWith" },
  { name: "Does not Start With", value: "notStartWith" },
  { name: "Ends With", value: "endWith" },
  { name: "Does not End With", value: "notEndWith" },
  { name: "Greater Than", value: "greaterThan" },
  { name: "Less Than", value: "lessThan" },
  { name: "After", value: "after" },
  { name: "Before", value: "before" },
  { name: "Equals", value: "equals" },
  { name: "Exists", value: "exists" },
  { name: "Does not Exist", value: "notExists" },
];

const type_options = [{ name: "webhook", value: "webhook" }];

const methods = [
  { name: "GET", value: "GET" },
  { name: "POST", value: "POST" },
];

type MyState = {
  selectedCondition: string;
  isEdit: boolean;
  destinations: any;
  testingTrigger: boolean;
  testResults: any;
  selectedDestinations: any;
  openAddNewOptionsModal: boolean;
  triggerName: string;
  startDate: string;
  endDate: string;
  optionObject: any;
  filterOptions: any;
  dynamicData: any;
};

class TriggerCard extends Component<TriggerCardInterfaceProps, MyState> {
  state: MyState = {
    // optional second annotation for better type inference
    selectedCondition: "text",
    isEdit: false,
    destinations: this.props.destinations,
    testingTrigger: false,
    testResults: {},
    selectedDestinations: [],
    openAddNewOptionsModal: false,
    triggerName: this.props.trigger.name,
    startDate: this.props.trigger.startDate.split('T')[0],
    endDate: this.props.trigger.endDate.split('T')[0],
    optionObject: {
      name: "",
      type: "",
      url: "",
      headers: "",
      method: "",
      emailRename: "",
    },
    filterOptions: JSON.parse(this.props.trigger.filterOptions),
    dynamicData: JSON.parse(this.props.trigger.dynamicData)
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.editTrigger = this.editTrigger.bind(this);
    this.handleChangeDestination = this.handleChangeDestination.bind(this);
    this.handleChangeAddOption = this.handleChangeAddOption.bind(this);
    this.getEdittedTriggerData = this.getEdittedTriggerData.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.inputForFilter = this.inputForFilter.bind(this);
    this.saveTriggerData = this.saveTriggerData.bind(this);
    this.addExtraFilter = this.addExtraFilter.bind(this)
    this.deleteTrigger = this.deleteTrigger.bind(this);
    this.testDestination = this.testDestination.bind(this);
    this.copy = this.copy.bind(this); // add this line
  }

  public componentDidMount(): void {
    let modifiedSelectedDestinations = [];
    modifiedSelectedDestinations = this.props.destinations &&
      this.props.destinations.length &&
      this.props.destinations.filter((item) => {
        if (this.props.trigger && this.props.trigger.destinationIds &&
          this.props.trigger.destinationIds.split(",").includes(item.id.toString())
        ) {
          return item;
        }
      });
    this.setState({ selectedDestinations: modifiedSelectedDestinations });
    this.props.emptyDestination();
    this.props.updateTriggerField("clientId", this.props.clientId)
  }

  public componentDidUpdate(
    prevProps: Readonly<TriggerCardInterfaceProps>,
    prevState: Readonly<MyState>,
    snapshot?: any
  ): void {
    let modifiedSelectedDestinations = [];
    if (this.props.destinations !== prevProps.destinations) {
      this.setState({ destinations: this.props.destinations });
      modifiedSelectedDestinations = this.props.destinations &&
        this.props.destinations.length &&
        this.props.destinations.filter((item) => {
          if (this.props.trigger && this.props.trigger.destinationIds &&
            this.props.trigger.destinationIds.split(",")
              .includes(item.id.toString())
          ) {
            return item;
          }
        });
      this.setState({ selectedDestinations: modifiedSelectedDestinations });
    }
    // s
    let filterOptions = this.state.filterOptions;
    if (this.state.isEdit != prevState.isEdit) {
      filterOptions =
        this.props.triggers.editedTrigger.filterOptions &&
        this.props.triggers.editedTrigger.filterOptions != undefined &&
        JSON.parse(this.props.triggers.editedTrigger.filterOptions);
      this.setState({
        filterOptions: filterOptions || {
          "filters": [
            {
              "field": "",
              "operator": "",
              "value": ""
            },
          ]
        }
      });
    }
  }

  public copy(e, key) {
    e.preventDefault();
    const tag = '{{' + key + '}}';
    navigator.clipboard.writeText('{{' + key + '}}');

    const el: any = document.activeElement;
    if (el.nodeName === 'INPUT' || el.nodeName === 'TEXTAREA') {
      const newCursorPos = el.selectionStart + tag.length;
      el.value =
        el.value.substring(0, el.selectionStart) +
        tag +
        el.value.substring(el.selectionEnd);
      el.setSelectionRange(newCursorPos, newCursorPos);

      this.handleChangeAddOption('url', el.value);
    }
  }
  
  private inputForFilter(condition: string) {
    let type = 'text';
    if (condition == "Greater Than" || condition == "Less Than") {
      type = 'number'
    } else if (
      condition == "After" ||
      condition == "Before" ||
      condition == "Equals"
    ) {
      type = "date";;
    } else {
      type = "text";;
    }
    return type
  }

  private setQuery(query) {
    console.log(query);
    console.log(formatQuery(query, 'json'));
    this.handleChange("filterOptions", formatQuery(query, 'json'));
    this.setState({ filterOptions: query });
  }

  private setDynamicData(field, value) {
    console.log(field);
    console.log(value);
    var dynamic = this.state.dynamicData;
    dynamic[field] = value;
    this.handleChange("dynamicData", JSON.stringify(dynamic));
    this.setState({ dynamicData: dynamic });
  }

  private handleChange(field: string, value: any, type?: any): void {
    let modifiedValue = value;
    if (field == "condition") {
      if (value.value == "Greater Than" || value.value == "Less Than") {
        this.setState({ selectedCondition: "number" });
      } else if (
        value.value == "After" ||
        value.value == "Before" ||
        value.value == "Equals"
      ) {
        this.setState({ selectedCondition: "date" });
      } else {
        this.setState({ selectedCondition: "text" });
      }
      return;
    } else if (type == "dropdown") {
      modifiedValue = value.value;
    }

    if (field == "name") {
      this.setState({ triggerName: modifiedValue });
    }
    this.props.updateTriggerField(field, modifiedValue);
  }

  private noEndChange(e): void {
    if (this.props.triggers.editedTrigger.endDate.split('T')[0] != '2200-01-01') {
      this.setState({ endDate: '2200-01-01' });
      this.handleChange('endDate', '2200-01-01');
    } else {
      const date = new Date();
      var endDate = new Date();
      endDate.setDate(endDate.getDate() + 30);
      this.setState({ endDate: endDate.toISOString().split('T')[0] });
      this.handleChange('endDate', endDate.toISOString().split('T')[0]);
    }
  }

  private handleChangeFilters(name: string, value: any, index: number) {
    let filters: any = this.state.filterOptions && this.state.filterOptions.filters

    if (name == 'filter_join') {
      filters[index] = value.value || value;
    } else {
      filters[index][name] = value.value || value;
    }
    this.handleChange("filterOptions", JSON.stringify({ filters: filters }))
  }

  public editTrigger = (event: boolean) => {
    this.setState({ isEdit: event });
  };

  public testDestination() {
    this.setState({ testingTrigger: true });
    this.props.testDestination(this.props.edittedDestination);
  }

  public handleChangeDestination = (event, type) => {
    if (event.target.value.includes("NEW")) {
      this.setState({ openAddNewOptionsModal: true });
      console.log("props. uuuu", this.props.edittedDestination)
      this.handleChangeAddOption("clientId", this.props.clientId)
      this.handleChangeAddOption("triggerId", this.props.trigger.id)
    } else {
      let selectedDestinations = removeDuplicateObjects(event.target.value, "id");
      this.setState({
        selectedDestinations: selectedDestinations,
      });
      this.props.updateTriggerField("destinationIds", selectedDestinations && selectedDestinations.length ? selectedDestinations.map((item: any) => item.id).join(',') : '');
    }
  };

  public editDestination(id, destination) {
    this.setState({ openAddNewOptionsModal: true });
    let optionObject = { ...destination };
    this.setState({ optionObject: optionObject });
    this.props.updateDestinationField('clientId', destination.clientId);
    this.props.updateDestinationField('id', id);
    this.props.updateDestinationField('name', destination.name);
    this.props.updateDestinationField('type', destination.type);
    this.props.updateDestinationField('method', destination.method);
    this.props.updateDestinationField('url', destination.url);
    this.props.updateDestinationField('emailRename', destination.emailRename);
    this.props.updateDestinationField('body', destination.body);
    this.props.updateDestinationField('headers', destination.headers);
    this.props.updateDestinationField('apiKey', destination.apiKey);
    this.props.updateDestinationField('customProperties', destination.customProperties);

  }

  public handleChangeAddOption = (name, value) => {
    let optionObject = { ...this.state.optionObject };
    optionObject[name] = value;
    this.setState({ optionObject: optionObject });
    console.log('updateDestinationField');
    this.props.updateDestinationField(name, value);
  };

  public saveOption = () => {
    console.log('saveOption');
    this.props.saveDestination(this.props.edittedDestination, this.props.history)

  };

  public getEdittedTriggerData = (triggerData) => {
    this.props.loadTriggerById2(triggerData);
  };

  public deleteDestination() {
    this.props.deleteDestination(this.props.edittedDestination.id);
    this.setState({ openAddNewOptionsModal: false });
  }

  public deleteTrigger() {
    this.props.deleteTrigger(this.props.triggers.editedTrigger.id);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  public closeTest() {
    this.setState({ testingTrigger: false });
  }

  public saveTriggerData() {
    this.props.saveTrigger(this.props.triggers.editedTrigger, this.props.history);
    this.editTrigger(false);
    console.log('reloading');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  private addExtraFilter() {
    let filters: any = this.state.filterOptions && [...this.state.filterOptions.filters]

    filters.push(...['AND', {
      "field": "",
      "operator": "",
      "value": ""
      ,
    }]);
    this.setState({ filterOptions: { filters: filters } })
  }

  public render(): JSX.Element {
    const state = this.state;
    const props = this.props;
    const { classes } = this.props;
    console.log(this);

    console.log("edited destination", props.edittedDestination)

    return (
      <>
        {state.openAddNewOptionsModal ? (
          <Dialog
            fullWidth
            aria-labelledby="customized-dialog-title"
            open={state.openAddNewOptionsModal}
            id="customize-dialog-box"
          >
            <div className="customized-dialog-header">
              <DialogTitle id="customized-dialog-title">
                {this.props.edittedDestination.id ? "Edit Option" : "Add New Option"}
              </DialogTitle>
              <div>
                {this.props.edittedDestination.id ?
                  <button id="test-btn" onClick={() => this.testDestination()}>Test</button>
                  : <></>}
                {this.props.edittedDestination.id ?
                  <button onClick={() => this.deleteDestination()}>Delete</button>
                  : <></>}
              </div>
            </div>
            <DialogContent dividers>
              {state.testingTrigger ? (
                <Dialog
                  fullWidth
                  aria-labelledby="customized-dialog-title"
                  className="customized-dialog-box"
                  open={state.testingTrigger}
                >
                  <section className="customized-dialog-box__code-section">{JSON.stringify(this.props.destination.testResults)}</section>
                  <section className="customized-dialog-box__button"><button onClick={() => this.closeTest()}>Done</button></section>
                </Dialog>) : <></>}
              <form
                //className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={this.saveOption}
              >
                <div className="flexBox">
                  <div id="w50" className="mb-4">
                    <label className="field_label">Name</label>
                    <input
                      name="name"
                      required
                      type="text"
                      value={props.edittedDestination.name}
                      onChange={(event) =>
                        this.handleChangeAddOption("name", event.target.value)
                      }
                    />
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Type</label>
                    <div className="select_multi">
                      <Select
                        name="type"
                        //value={state.selectedDestinations}
                        value={(props.edittedDestination.type) || []}
                        onChange={(event) =>
                          this.handleChangeAddOption("type", event.target.value)
                        }
                        variant="outlined"
                      >
                        {type_options.map((option) => (
                          <MenuItem key={option.name} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div id="w100" className="mb-4">
                    <label className="field_label">URL</label>
                    <input
                      name="url"
                      required
                      type="text"
                      // value={this.props.clients.editedClient.client_name}
                      //value="305-000-0000"
                      value={props.edittedDestination.url}
                      onChange={(event) =>
                        this.handleChangeAddOption("url", event.target.value)
                      }
                      disabled={!state.isEdit}
                    />
                    <div className="options_buttons_area">
                      {Object.keys(options).map(k => (
                        <span key={k} className="options_button" onMouseDown={e => this.copy(e, k)}>
                          {options[k]}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Headers</label>
                    <input
                      name="headers"
                      required
                      type="text"
                      value={props.edittedDestination.headers}
                      onChange={(event) =>
                        this.handleChangeAddOption(
                          "headers",
                          event.target.value
                        )
                      }
                      disabled={!state.isEdit}
                    />
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Method</label>
                    <div className="select_multi">
                      <Select
                        name="method"
                        value={props.edittedDestination.method || []}
                        onChange={(event) =>
                          this.handleChangeAddOption(
                            "method",
                            event.target.value
                          )
                        }
                        variant="outlined"
                      >
                        {methods.map((option) => (
                          <MenuItem key={option.name} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Body</label>
                    <input
                      name="body"
                      required
                      type="text"
                      value={props.edittedDestination.body}
                      onChange={(event) =>
                        this.handleChangeAddOption("body", event.target.value)
                      }
                    />
                  </div>
                  <div id="w50" className="mb-4">
                    <label className="field_label">Custom Properties</label>
                    <input
                      name="customProperties"
                      required
                      type="text"
                      value={props.edittedDestination.customProperties}
                      onChange={(event) =>
                        this.handleChangeAddOption("customProperties", event.target.value)
                      }
                    />
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <button
                className="button secondary mr-3"
                onClick={() => this.setState({ openAddNewOptionsModal: false })}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button accent"
                onClick={() => {
                  this.setState({ openAddNewOptionsModal: false });
                  this.saveOption();
                }}
              >
                Save Changes
              </button>
            </DialogActions>
          </Dialog>
        ) : null}
        <div className="trigger_card">
          <div className="d-flex trigger_card__heading mb-4">
            {state.isEdit ? (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">Name</label>
                <input
                  required
                  type="text"
                  value={props.triggers.editedTrigger.name}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("name", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : (
              <h3>{state.triggerName || "Trigger Name Example"}</h3>
            )}

            {state.isEdit ? (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">Start Date</label>
                <input
                  required
                  type="date"
                  value={props.triggers.editedTrigger.startDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("startDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : (
              <div id="w33" className="d-flex">
                <label className="field_label mr-4 mb-0">Start Date</label>
                <input
                  required
                  type="date"
                  value={state.startDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("startDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            )}

            {state.isEdit ? (
              <div className="d-flex">
                <label className="field_label mr-4 mb-0">No End?</label>
                <input
                  required
                  type="checkbox"
                  checked={props.triggers.editedTrigger.endDate.split('T')[0] == '2200-01-01'}
                  //value="305-000-0000"
                  onChange={(e) =>
                    this.noEndChange("noEnd")
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : (
              <div className="d-flex">
                <label className="field_label mr-4 mb-0">No End?</label>
                <input
                  required
                  type="checkbox"
                  checked={state.endDate.split('T')[0] == '2200-01-01'}
                  //value="305-000-0000"
                  onChange={(e) =>
                    this.noEndChange("noEnd")
                  }
                  disabled={!state.isEdit}
                />
              </div>
            )}

            {state.isEdit && state.endDate.split('T')[0] != '2200-01-01' ? (
              <div className="d-flex">
                <label className="field_label mr-4 mb-0">End Date</label>
                <input
                  required
                  type="date"
                  value={props.triggers.editedTrigger.endDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("endDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : state.endDate.split('T')[0] != '2200-01-01' ? (
              <div className="d-flex">
                <label className="field_label mr-4 mb-0">End Date</label>
                <input
                  required
                  type="date"
                  value={state.endDate.split('T')[0]}
                  //value="305-000-0000"
                  onChange={(event) =>
                    this.handleChange("endDate", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            ) : ''}

            {state.isEdit ? (
              <div>
                <button
                  className="delete-button"
                  onClick={() => this.deleteTrigger()}
                >
                  Delete
                </button>
                <button
                  className="button secondary mr-3"
                  onClick={() => this.editTrigger(false)}
                >
                  Cancel
                </button>
                <button
                  className="button accent"
                  onClick={() => this.saveTriggerData()}
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                className="button secondary"
                onClick={() => {
                  this.editTrigger(true);
                  this.getEdittedTriggerData(this.props.trigger);
                }}
              >
                Edit Trigger
              </button>
            )}
          </div>
          {!state.isEdit &&
            props.trigger.filterOptions ?
            (
              <div>
                <QueryBuilder disabled fields={fields} query={this.state.filterOptions} onQueryChange={q => this.setQuery(q)} />
                <div className="pt15 time_on_page">
                  <div id="w50" className="d-flex">
                    <label className="field_label mr-4 mb-0">Time On Page In Seconds</label>
                    <input
                      required
                      type="number"
                      value={this.state.dynamicData.timeOnPage}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.setDynamicData("timeOnPage", event.target.value)
                      }
                      disabled={!state.isEdit}
                    />
                  </div>
                </div>
                <div className="pt15">
                  <label
                    id="destination-multi-label"
                    className="field_label"
                  >
                    Destination
                  </label>
                  <div className="select_multi">
                    <Select
                      labelId="destination-multi-label"
                      id="destination-multi"
                      multiple
                      value={state.selectedDestinations || []}
                      onChange={this.handleChangeDestination}
                      // renderValue={(selected) => (selected as string[]).join(', ')}
                      renderValue={(selected) =>
                        Array.from((selected) as any)
                          .map((item: any) => item.name)
                          .join(", ")
                      }
                      variant="outlined"
                      disabled={!state.isEdit}
                      name="destination-multi"
                    >
                      <MenuItem
                        value="NEW"
                      // classes={{ root: classes.checked }}

                      >
                        <AddIcon
                          fontSize="small"
                          style={{
                            marginRight: "1rem",
                            marginLeft: "0.5rem",
                          }}
                        />
                        Add New
                      </MenuItem>
                      {state.destinations.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          <Checkbox
                            // classes={{
                            //   checked: classes.checked,
                            //   colorSecondary: classes.colorSecondary,
                            // }}

                            checked={containsObject(
                              option,
                              state.selectedDestinations
                            )}
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            ) :
            (
              <div id="test">
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                  <QueryBuilder fields={fields} query={this.state.filterOptions} onQueryChange={q => this.setQuery(q)} />
                </QueryBuilderDnD>
                <div className="pt15">
                  <div id="w33" className="d-flex">
                    <label className="field_label mr-4 mb-0">Time On Page</label>
                    <input
                      required
                      type="number"
                      value={this.state.dynamicData.timeOnPage}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.setDynamicData("timeOnPage", event.target.value)
                      }
                      disabled={!state.isEdit}
                    />
                  </div>
                </div>
                <div className="pt15">
                  <label
                    id="destination-multi-label"
                    className="field_label"
                  >
                    Destination
                  </label>
                  <div className="select_multi">
                    <Select
                      labelId="destination-multi-label"
                      id="destination-multi"
                      multiple
                      value={state.selectedDestinations || []}
                      onChange={this.handleChangeDestination}
                      // renderValue={(selected) => (selected as string[]).join(', ')}
                      renderValue={(selected) =>
                        Array.from((selected) as any)
                          .map((item: any) => item.name)
                          .join(", ")
                      }
                      variant="outlined"
                      disabled={!state.isEdit}
                      name="destination-multi"
                    >
                      <MenuItem
                        value="NEW"
                        // classes={{ root: classes.checked }}
                        onClick={(e) => { this.props.emptyDestination() }}
                      >
                        <AddIcon
                          fontSize="small"
                          style={{
                            marginRight: "1rem",
                            marginLeft: "0.5rem",
                          }}
                        />
                        Add New
                      </MenuItem>
                      {state.destinations.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          <Checkbox
                            // classes={{
                            //   checked: classes.checked,
                            //   colorSecondary: classes.colorSecondary,
                            // }}

                            checked={containsObject(
                              option,
                              state.selectedDestinations
                            )}
                          />
                          <ListItemText primary={option.name} />
                          <ListItemIcon>
                            <EditIcon onClick={(e) => { this.editDestination(option.id, option) }} />
                          </ListItemIcon>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )
          }

          {/*!state.isEdit &&
            props.trigger.filterOptions &&
            JSON.parse(props.trigger.filterOptions) && JSON.parse(props.trigger.filterOptions).filters && JSON.parse(props.trigger.filterOptions).filters.length ? (
            <div>
              <div>
                {JSON.parse(props.trigger.filterOptions).filters.map(
                  (item, index) => (
                    <>
                      {typeof item === "object" && item !== null ? (
                        <div className="flexBox">
                          <div id="w33">
                            <label className="field_label">Filter</label>
                            <DropdownComponent
                              name={"filter_options"}
                              options={filter_options}
                              value={item.field}
                              handleChange={this.handleChange}
                              isDisabled={state.isEdit}
                            />
                          </div>

                          <div id="w67">
                            <label className="field_label">Condition</label>
                            <div className="flexBox">
                              <div id="w50">
                                <DropdownComponent
                                  name={"condition"}
                                  options={condition_options}
                                  value={item.operator}
                                  handleChange={this.handleChange}
                                  isDisabled={state.isEdit}
                                />
                              </div>
                              <div id="w50">
                                <input
                                  required
                                  type={state.selectedCondition}
                                  // value={this.props.clients.editedClient.client_name}
                                  //value="305-000-0000"
                                  onChange={(event) =>
                                    this.handleChange(
                                      "description",
                                      event.target.value
                                    )
                                  }
                                  disabled={!state.isEdit}
                                  value={!state.isEdit ? item.value : null}
                                />
                              </div>
                            </div>
                          </div>


                        </div>
                      ) : (
                        <div className="add_filter_container">
                          <div style={{ width: "6rem" }} className="mb-auto">
                            <DropdownComponent
                              name={"filter_join"}
                              options={[
                                { name: "AND", value: "AND" },
                                { name: "OR", value: "OR" },
                              ]}
                              value={item}
                              handleChange={this.handleChange}
                              isDisabled={state.isEdit}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )
                )}
              </div>
              <div className="pt15">
                <label
                  id="destination-multi-label"
                  className="field_label"
                >
                  Destination
                </label>
                <div className="select_multi">
                  <Select
                    labelId="destination-multi-label"
                    id="destination-multi"
                    multiple
                    value={Array.from(state.selectedDestinations)}
                    onChange={this.handleChangeDestination}
                    renderValue={(selected) =>
                      Array.from((selected) as any)
                        .map((item: any) => item.name)
                        .join(", ")
                    }

                    variant="outlined"
                    disabled={!state.isEdit}
                    name="destination-multi"
                  >
                    <MenuItem
                      value="NEW"
                    // classes={{ root: classes.checked }}

                    >
                      <AddIcon
                        fontSize="small"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                      Add New
                    </MenuItem>
                    {state.destinations.map((option) => (
                      <MenuItem key={option.id} value={option}>
                        <Checkbox
                          // classes={{
                          //   checked: classes.checked,
                          //   colorSecondary: classes.colorSecondary,
                          // }}

                          checked={containsObject(
                            option,
                            state.selectedDestinations
                          )}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          ) : !state.isEdit && (
            <div className="flexBox">
              <div id="w25">
                <label className="field_label">Filter</label>
                <DropdownComponent
                  name={"filter_options"}
                  options={filter_options}
                  value={""}
                  handleChange={null}
                  isDisabled={state.isEdit}
                />
              </div>

              <div id="w50">
                <label className="field_label">Condition</label>
                <div className="flexBox">
                  <div id="w50">
                    <DropdownComponent
                      name={"condition"}
                      options={condition_options}
                      value={""}
                      handleChange={null}
                      isDisabled={state.isEdit}
                    />
                  </div>
                  <div id="w50">
                    <input
                      required
                      type="text"
                      // value={this.props.clients.editedClient.client_name}
                      //value="305-000-0000"
                      onChange={(event) =>
                        this.handleChange("description", event.target.value)
                      }
                      disabled={!state.isEdit}
                      value={""}
                    />
                  </div>
                </div>
              </div>

              <div id="w25">
                <label id="destination-multi-label" className="field_label">
                  Destination
                </label>
                <div className="select_multi">
                  <Select
                    labelId="destination-multi-label"
                    id="destination-multi"
                    multiple
                    value={state.selectedDestinations || []}
                    onChange={this.handleChangeDestination}
                    // renderValue={(selected) => (selected as string[]).join(', ')}
                    renderValue={(selected) =>
                      Array.from((selected) as any).map((item: any) => item.name).join(", ")
                    }
                    variant="outlined"
                    disabled={!state.isEdit}
                    name="destination-multi"
                  ></Select>
                </div>
              </div>
            </div>
          )*/}
          {/*state.isEdit && state.filterOptions && state.filterOptions.filters && state.filterOptions.filters.length

            ? (
              <div>
                <div>
                  {state.filterOptions.filters.map((item, index) => (
                    <>
                      {typeof item === "object" && item !== null ? (
                        <div className="flexBox">
                          <div id="w33">
                            <label className="field_label">Filter</label>
                            <DropdownComponent
                              name={"field"}
                              options={filter_options}
                              value={item.field}
                              handleChange={this.handleChangeFilters}
                              isDisabled={state.isEdit}
                              index={index}
                            />
                          </div>

                          <div id="w67">
                            <label className="field_label">Condition</label>
                            <div className="flexBox">
                              <div id="w50">
                                <DropdownComponent
                                  name={"operator"}
                                  options={condition_options}
                                  value={item.operator}
                                  handleChange={this.handleChangeFilters}
                                  isDisabled={state.isEdit}
                                  index={index}
                                />
                              </div>
                              <div id="w50">
                                <input
                                  required
                                  type={this.inputForFilter(item.operator)}
                                  onChange={(event) => this.handleChangeFilters("value", event.target.value, index)}
                                  disabled={!state.isEdit}
                                  value={state.isEdit ? item.value : null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="add_filter_container">
                          <div style={{ width: "6rem" }} className="mb-auto">
                            <DropdownComponent
                              name={"filter_join"}
                              options={[
                                { name: "AND", value: "AND" },
                                { name: "OR", value: "OR" },
                              ]}
                              value={item}
                              handleChange={this.handleChangeFilters}
                              isDisabled={state.isEdit}
                              index={index}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
                {state.isEdit ? (
                  <div className="add_filter_container">
                    <button className="button tertiary" onClick={this.addExtraFilter}>+ Add Filter</button>
                  </div>
                ) : null}
                <div className="pt15">
                  <label
                    id="destination-multi-label"
                    className="field_label"
                  >
                    Destination
                  </label>
                  <div className="select_multi">
                    <Select
                      labelId="destination-multi-label"
                      id="destination-multi"
                      multiple
                      value={state.selectedDestinations || []}
                      onChange={this.handleChangeDestination}
                      // renderValue={(selected) => (selected as string[]).join(', ')}
                      renderValue={(selected) =>
                        Array.from((selected) as any)
                          .map((item: any) => item.name)
                          .join(", ")
                      }
                      variant="outlined"
                      disabled={!state.isEdit}
                      name="destination-multi"
                    >
                      <MenuItem
                        value="NEW"
                      // classes={{ root: classes.checked }}

                      >
                        <AddIcon
                          fontSize="small"
                          style={{
                            marginRight: "1rem",
                            marginLeft: "0.5rem",
                          }}
                        />
                        Add New
                      </MenuItem>
                      {state.destinations.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          <Checkbox
                            // classes={{
                            //   checked: classes.checked,
                            //   colorSecondary: classes.colorSecondary,
                            // }}

                            checked={containsObject(
                              option,
                              state.selectedDestinations
                            )}
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>)
            : null*/
          }
        </div>
      </>
    );
  }
}

export default styled(TriggerCard)(({ theme }) => (styles));
