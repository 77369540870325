export const LOAD_INTEGRATIONS = "LOAD_INTEGRATIONS";
export const LOAD_INTEGRATIONS_SUCCESS = "LOAD_INTEGRATIONS_SUCCESS";
export const SAVE_INTEGRATION = "SAVE_INTEGRATION";
export const SAVE_INTEGRATION_SUCCESS = "SAVE_INTEGRATION_SUCCESS";
export const SAVE_INTEGRATION_PENDING = "SAVE_INTEGRATION_PENDING";
export const CHANGE_ACCOUNT = "CHANGE_ACCOUNT";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export interface IntegrationState {
    integrations: Array<Integration>
    loading: boolean
    loadingIntegration: boolean
    integrationsSortOptions: {
        field: string
        direction: boolean
    }
    adAccountLists: any
    selectedAccount: string
    redirectUser: boolean
    message: string
}

export interface Integration {
    id?: number
    clientId: number
    name: string
    type: string
    authenticationMethod: string
}

interface SaveIntegration {
    type: typeof SAVE_INTEGRATION_SUCCESS | typeof SAVE_INTEGRATION | typeof SAVE_INTEGRATION_PENDING,
    payload: any
}

interface LoadIntegrations {
    type: typeof LOAD_INTEGRATIONS_SUCCESS | typeof LOAD_INTEGRATIONS,
    payload: any
}

interface ChangeAccount {
    type: typeof CHANGE_ACCOUNT,
    payload: any
}

interface ClearMessage {
    type: typeof CLEAR_MESSAGE,
    payload: any
}

export type IntegrationActionTypes = SaveIntegration | LoadIntegrations | ChangeAccount | ClearMessage;
