import { MENU_TOGGLE, CLIENT_MENU_TOGGLE, SELECT_CLIENT, CHECK_ADMIN_RIGHTS } from './types'
import { Session } from '../auth/types'
import { LOAD_CLIENT_BY_ID_SUCCESS } from '../clients/types'
import { LOAD_SCRIPTS, LOAD_SCRIPTS_SUCCESS, LOAD_SCRIPT_BY_ID, LOAD_SCRIPT_BY_ID_SUCCESS } from '../scripts/types'
import axios from '../../lib/axios'
import config from '../../config/app-config.json';

export const menuToggle = () => ({
    type: MENU_TOGGLE
})

export const toggleClientMenu = () => ({
    type: CLIENT_MENU_TOGGLE
})


export const selectClient = (account: any, history) => {
    history.history.push('/');
    return (dispatch: any) => {
        dispatch({
            type: SELECT_CLIENT,
            payload: null
        })

        localStorage.setItem('lastSelectedClient', JSON.stringify(account));

        setTimeout(() => {
            dispatch({
                type: SELECT_CLIENT,
                payload: account
            })
            dispatch({
                type: LOAD_CLIENT_BY_ID_SUCCESS,
                payload: account
            })
        }, 0)
        setTimeout(() => {
            dispatch({
                type: LOAD_SCRIPT_BY_ID,
                payload: null
            })
            axios.get(`${config.serviceUrl}/script/`, { params: { client_id : account.id } }).then((response) => {
                dispatch({
                    type: LOAD_SCRIPT_BY_ID_SUCCESS,
                    payload: response.data.data[0]
                })
            })
        }, 0)
    }
}

export const selectInitialClient = (accounts: any) => {
    console.log(accounts);
    return (dispatch: any) => {
        dispatch({
            type: SELECT_CLIENT,
            payload: null
        })

        let lastSelected: any = localStorage.getItem('lastSelectedClient');
        if (lastSelected !== null) {
            console.log(lastSelected);
            lastSelected = JSON.parse(lastSelected);
            dispatch({
                type: SELECT_CLIENT,
                payload: lastSelected
            })
            dispatch({
                type: LOAD_CLIENT_BY_ID_SUCCESS,
                payload: lastSelected
            })
            dispatch({
                type: LOAD_SCRIPT_BY_ID,
                payload: null
            })
            axios.get(`${config.serviceUrl}/script/`, { params: { client_id : lastSelected.id } }).then((response) => {
                dispatch({
                    type: LOAD_SCRIPT_BY_ID_SUCCESS,
                    payload: response.data.data[0]
                })
            })
        } else {
            let session = localStorage.getItem('session');
            let decodedSession: Session = session === null ? session : JSON.parse(session);
            console.log(decodedSession);
            if (decodedSession !== null) {
                for (let account of accounts) {
                    console.log(account);
                    if (account.clientEmail === decodedSession.user.email) {
                        dispatch({
                            type: SELECT_CLIENT,
                            payload: account
                        })

                        dispatch({
                            type: LOAD_CLIENT_BY_ID_SUCCESS,
                            payload: account
                        })
                        dispatch({
                            type: LOAD_SCRIPT_BY_ID,
                            payload: null
                        })
                        axios.get(`${config.serviceUrl}/script/`, { params: { client_id : account.id } }).then((response) => {
                            dispatch({
                                type: LOAD_SCRIPT_BY_ID_SUCCESS,
                                payload: response.data.data[0]
                            })
                        })
                    }
                }
            }
        }
    }
}

export const checkAdminRights = () => ({
    type: CHECK_ADMIN_RIGHTS
})