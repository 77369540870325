import { LOAD_SCRIPTS_SUCCESS, UPDATE_SCRIPT_FIELD, LOAD_SCRIPT_BY_ID, LOAD_SCRIPT_BY_ID_SUCCESS, LOAD_SCRIPTS, UPDATE_SCRIPT_SUCCESS, CREATE_SCRIPT_SUCCESS, Script, REMOVE_SCRIPT_BY_ID, REMOVE_SCRIPT_BY_ID_SUCCESS,  SORT_SCRIPTS } from "./types";
import store from '../../store';
import axios from '../../lib/axios';
import config from '../../config/app-config.json';

export const updateScriptField = (field, value) => {
    return {
        type: UPDATE_SCRIPT_FIELD,
        payload: { field, value }
    }
}

export const loadScriptById = id => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_SCRIPT_BY_ID,
            payload: null
        })
        axios.get(`${config.serviceUrl}/script/`, { params: { client_id : id } }).then((response) => {
            dispatch({
                type: LOAD_SCRIPT_BY_ID_SUCCESS,
                payload: response.data.data[0]
            })
        })
    }
}

export const loadScript = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_SCRIPTS,
            payload: null
        })
        axios.get(`${config.serviceUrl}/script/`).then((response) => {
            dispatch({
                type: LOAD_SCRIPTS_SUCCESS,
                payload: response.data.data
            })
        })
    }
}

export const saveScript = (script: Script) => {
    return (dispatch: any) => {
        axios.post(`${config.serviceUrl}/script`, script).then((response) => {
            // history.push(`/`);
            // history.push(`/account/${account.id}`);
            dispatch({
                type: UPDATE_SCRIPT_SUCCESS,
                payload: response
            })
        })
    }
}