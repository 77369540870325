import React, { Component } from "react";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { withRouter } from "react-router";
import { HashLoader } from "react-spinners";
import { NavLink as Link } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";
import { ScriptsState } from "../../store/scripts/types";

import "./accounts.css";
import { ReactComponent as CopyToClipboard } from '../../icons/copy_clipboard.svg';
import { Alert, Snackbar } from "@mui/material";



interface ScriptDetailsSectionInterfaceProps {
  clients: ClientsState;
  emptyClient: Function;
  updateClientField: Function;
  updateScriptField: Function;
  scripts: ScriptsState;
  saveClient: Function;
  saveScript: Function;
}

type MyState = {
  copyToClipboardDone: boolean;
  isEdit: boolean;
};

class ScriptDetailsSectionSection extends Component<ScriptDetailsSectionInterfaceProps> {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.editScript = this.editScript.bind(this);
  }

  state: MyState = {
    // optional second annotation for better type inference
    copyToClipboardDone: false,
    isEdit: false
  };

  public componentDidMount(): void { }

  private getScript(): string {
    return ``;
  }

  public edit(id): void { }

  private handleChange(field: string, value: any): void {
    if (field === "frequency" && value > 1000) {
      value = 1000;
    }
    this.props.updateScriptField(field, value);
  }

  public editScript = (event: boolean, type?: any) => {
    this.setState({ isEdit: event });
    if (event == false && type == "save") {
      this.props.saveScript(this.props.scripts.editedScript);
    }
  };

  public render(): JSX.Element {
    console.log(this);
    const { scripts } = this.props;
    const { isEdit } = this.state;
    return (
      <div className="general_detail_section">
        <div className="section_title">
          <h2>Script Details</h2>
          {isEdit ? (
            <div>
              <button
                className="button secondary mr-3"
                onClick={() => this.editScript(false)}
              >
                Cancel
              </button>
              <button
                className="button accent"
                onClick={() => {
                  this.editScript(false, "save");
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <button
              className="button accent"
              onClick={() => this.editScript(true)}
            >
              Edit
            </button>
          )}
        </div>

        <div className="form_details">
          <div className="form_title">Client ID</div>
          <div className="flexBox">
            <div id="w100">
              <label className="field_label fw-600">{this.props.clients.editedClient.id}</label>
            </div>
          </div>
        </div>

        <div className="form_details">
          <div className="form_title">Extra Credits</div>
          <div>Per Rebill</div>
          <label className="field_label"></label>
          <div>
            <input
              required
              type="text"
              // value={this.props.clients.editedClient.frequency || 1}
              value={this.props.scripts.editedScript.extraCredsPerBill || 0}
              onChange={(event) =>
                this.handleChange("extraCredsPerBill", parseInt(event.target.value))
              }
              disabled={!isEdit}
            />
          </div>
        </div>

        <div className="form_details">
          <div className="form_title">Frequency</div>
          <div>1000 days max</div>
          <label className="field_label"></label>
          <div>
            <input
              required
              type="text"
              // value={this.props.clients.editedClient.frequency || 1}
              value={this.props.scripts.editedScript.uniquePerDay || ''}
              onChange={(event) =>
                this.handleChange("uniquePerDay", parseInt(event.target.value))
              }
              disabled={!isEdit}
            />
          </div>
        </div>

        <div className="form_details">
          <div className="form_title">Script</div>

          <div className="flexBox">
            <div id="w100">
              <label className="field_label fw-600">
                Tag <CopyToClipboard onClick={() => { navigator.clipboard.writeText(this.getScript()); this.setState({ copyToClipboardDone: true }) }} className="clipboard_icon" />
              </label>
              <div>
                <div>
                  <textarea
                    value={this.getScript()}
                    onChange={(event) =>
                      this.handleChange("code", event.target.value)
                    }
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.copyToClipboardDone} autoHideDuration={6000} onClose={() => this.setState({ copyToClipboardDone: false })}>
          <Alert onClose={() => this.setState({ copyToClipboardDone: false })} severity="info" sx={{ width: '100%' }}>
            Copied to clipboard
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default ScriptDetailsSectionSection;
