import { LOAD_CLIENTS_SUCCESS, UPDATE_CLIENT_FIELD, LOAD_CLIENT_BY_ID, LOAD_CLIENT_BY_ID_SUCCESS, LOAD_CLIENTS, UPDATE_CLIENT_SUCCESS, CREATE_CLIENT_SUCCESS, Client, REMOVE_CLIENT_BY_ID, REMOVE_CLIENT_BY_ID_SUCCESS,  SORT_CLIENTS, FILTER_CLIENTS } from "./types";
import store from '../../store';
import axios from '../../lib/axios';
import config from '../../config/app-config.json';
import { selectInitialClient } from "../system/actions";

export const emptyClient = () => {
    return {
        type: CREATE_CLIENT_SUCCESS,
        payload: null
    }
}

export const loadClientsForAPI = () => {
    return axios.get(`${config.serviceUrl}/clients`);
}

export const updateClientField = (field, value) => {
    console.log('this is being called');
    return {
        type: UPDATE_CLIENT_FIELD,
        payload: { field, value }
    }
}

export const loadClients = (history = null, redirect = false) => {
    return (dispatch: any) => {
        dispatch({
            type: LOAD_CLIENTS,
            payload: []
        })
        axios.get(`${config.serviceUrl}/clients`).then((response) => {
            dispatch({
                type: LOAD_CLIENTS_SUCCESS,
                payload: response.data.data
            })

            dispatch(selectInitialClient(response.data.data));
            // if(history !== null && redirect) {
            //     history.push(`/account/${response.data.data[0].id}`);
            // }
        })
    }
}

export const loadClientById = id => {
  return (dispatch: any) => {
      dispatch({
          type: LOAD_CLIENT_BY_ID,
          payload: null
      })
      axios.get(`${config.serviceUrl}/clients/${id}`).then((response) => {
          dispatch({
              type: LOAD_CLIENT_BY_ID_SUCCESS,
              payload: response.data.data[0]
          })
      })
  }
}

export const saveClient = (account: Client) => {
    return (dispatch: any) => {
        axios.put(`${config.serviceUrl}/clients/${account.id}`, account).then((response) => {
            // history.push(`/`);
            // history.push(`/account/${account.id}`);
            dispatch({
                type: UPDATE_CLIENT_SUCCESS,
                payload: response
            })
        })
    }
}

export const filterClients = (value: any) => ({
    type: FILTER_CLIENTS,
    payload: value
})