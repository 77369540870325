import React, { Component } from "react";

import { connect } from "react-redux";

import { AppState } from '../../store';
import { HashLoader } from "react-spinners";
import { NavLink as Link, withRouter, useHistory, Redirect } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";
import AppHeader from "../../components/header/header";
import { loadClients } from "../../store/clients/actions";
import { SystemState } from "../../store/system/types";
import Spinner from "../../components/spinner";
import { IntegrationState } from "../../store/integrations/types";
import { changeAccountId, loadIntegrations, reSaveIntegration, saveIntegration } from "../../store/integrations/actions";
import { Select, MenuItem } from "@mui/material";
import Paginate from '../../components/pagination';

import './style.css';

interface IntegrationInterfaceProps {
  loadClients: typeof loadClients;
  loadIntegrations: typeof loadIntegrations;
  saveIntegration: typeof saveIntegration;
  changeAccountId: typeof changeAccountId;
  reSaveIntegration: typeof reSaveIntegration;
  clients: ClientsState;
  system: SystemState;
  integrations: IntegrationState;
  history: History;
  currentPage: number;
}

class Integrations extends React.Component<IntegrationInterfaceProps> {
  constructor(props) {
    console.log(props);
    super(props);

    this.state = {
      currentPage: 0,
    }
    //this.props.loadClients();
  }

  public componentDidMount(): void {
    this.props.loadIntegrations();
    console.log(this);
    const params = new URL(location.href).searchParams;
    const stateParam = params.get('state');
    console.log(params);
    console.log(stateParam);

    if (stateParam != null && stateParam != undefined) {
      const code = params.get('code');
      console.log('Should be saving integration.')
      this.props.saveIntegration({ code: code, state: stateParam }, history)
    }
  }

  private changeAccount(value): void {
    this.props.changeAccountId(value);
  }

  private reSave(): void {
    const queryparams = new URL(location.href).searchParams;
    const state_param = queryparams.get('state');
    const code_param = queryparams.get('code');

    console.log(state_param);
    console.log(code_param);
    this.props.reSaveIntegration({ accountId: this.props.integrations.selectedAccount, state: state_param });
    //let history = useHistory();
    //history.push("/triggers");
  }


  public render(): JSX.Element {
    const props = this.props;
    const state = this.state;
    //this.props.loadClients();
    return (
      <>
      {this.props.integrations.redirectUser ? (
         <Redirect to="/accounts" />
       ) : null}
        {this.props.integrations.adAccountLists.length > 0 ?
          <div className="form_details">
            <div className="form_title"><h1>Required Info</h1></div>
            <div className="flexBox">
              <div id="w100">
                <label className="field_label"><h3>Ad Account Selection</h3></label>
                <div>
                  <Select
                    required
                    placeholder="Select Account"
                    value={this.props.integrations.selectedAccount}
                    onChange={(event) =>
                      this.changeAccount(event.target.value)
                    }>
                    {this.props.integrations.adAccountLists.map((e, key) => {
                      return <MenuItem key={key} value={e.account_id}>{e.name}</MenuItem>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <input
            className="form_details--button"
            disabled={this.props.integrations.selectedAccount == ''}
            type="button"
            value="Create My Audience"
            onClick={() => this.reSave()}
            />
          </div >
          : <></>
        }
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  clients: state.clients,
  scripts: state.scripts,
  system: state.system,
  integrations: state.integrations
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadClients: () => dispatch(loadClients()),
    loadIntegrations: () => dispatch(loadIntegrations()),
    saveIntegration: (data?, history?) => dispatch(saveIntegration(data, history)),
    reSaveIntegration: (data?) => dispatch(reSaveIntegration(data)),
    changeAccountId: (data) => dispatch(changeAccountId(data))
  };
};


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Integrations)
);
