import React, { Component } from "react";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { withRouter } from "react-router";
import { HashLoader } from "react-spinners";
import { NavLink as Link } from "react-router-dom";
import { ClientsState } from "../../store/clients/types";

import "./accounts.css";

interface BankingDetailSectionInterfaceProps {
  clients: ClientsState;
  emptyClient: Function;
  updateClientField: Function;
  saveClient: Function;
}

type MyState = {
  isEdit: boolean;
};

class BankingDetailSection extends Component<BankingDetailSectionInterfaceProps> {
  state: MyState = {
    // optional second annotation for better type inference
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.editAccount = this.editAccount.bind(this);
  }

  public componentDidMount(): void {}

  public getClients() {
    return 1;
  }

  private handleChange(field: string, value: any): void {
    this.props.updateClientField(field, value);
  }

  public editAccount = (event: boolean, type?: any) => {
    this.setState({ isEdit: event });
    if (event == false && type == "save") {
      this.props.saveClient(this.props.clients.editedClient);
    }
  };
  public render(): JSX.Element {
    const state = this.state;
    return (
      <div className="general_detail_section">
        <div className="section_title">
          <h2>Banking Details</h2>
          {this.state.isEdit ? (
            <div>
              <button
                className="button secondary mr-3"
                onClick={() => this.editAccount(false)}
              >
                Cancel
              </button>
              <button
                className="button accent"
                onClick={() => {
                  this.editAccount(false, "save");
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <button
              className="button accent"
              onClick={() => this.editAccount(true)}
            >
              Edit
            </button>
          )}
        </div>

        <div className="form_details">
          <div className="form_title">Account Information</div>

          <div className="flexBox">
            <div id="w50">
              <label className="field_label">Routing number</label>
              <div>
                <input
                  required
                  type="text"
                  value={this.props.clients.editedClient.routingNumber}
                  onChange={(event) =>
                    this.handleChange("routingNumber", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            </div>

            <div id="w50">
              <label className="field_label">Account number</label>
              <div>
                <input
                  required
                  type="text"
                  value={this.props.clients.editedClient.accountNumber}
                  onChange={(event) =>
                    this.handleChange("accountNumber", event.target.value)
                  }
                  disabled={!state.isEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankingDetailSection;
