import React, { useState } from 'react'
import { BsSearch } from "react-icons/bs";
import './search.css';

export const Search = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const handleChange = (e) => {setSearchTerm(e.target.value)}

  return (
    <article className="search-field">
      <BsSearch />
      <input type="text" placeholder="Search by keyword..." value={searchTerm} onChange={handleChange}></input>
    </article>
  )
}
