export const LOAD_REPORTS = "LOAD_REPORTS"
export const LOAD_REPORTS_SUCCESS = "LOAD_REPORTS_SUCCESS";
export const SORT_REPORTS = "SORT_PB_REPORTS";
export const FILTER_DATE = "FILTER_DATE";
export const FILTER_DATE_TYPE = "FILTER_DATE_TYPE";
export const RESET_STATE = "RESET_STATE";

export interface ReportState {
    report: string
    loading: boolean
    filterByDateType: {
        label: string
        value: string
    }
    filterByDate: {
        to: Date
        from: Date
    }
    reportSortOptions: {
        field: string
        direction: boolean
    }
}

export interface Report {
    name?: string
}

interface LoadReports {
    type: typeof LOAD_REPORTS_SUCCESS | typeof LOAD_REPORTS
    payload: string
}

interface SortReports {
    type: typeof SORT_REPORTS
    payload: any
}

interface FilterByDate {
    type: typeof FILTER_DATE
    payload: any
}

interface FilterByDateType {
    type: typeof FILTER_DATE_TYPE
    payload: any
}

interface ResetState {
    type: typeof RESET_STATE,
    payload: any
}

export type AdminReportsActionTypes =
    LoadReports |
    SortReports |
    FilterByDate |
    FilterByDateType |
    ResetState
