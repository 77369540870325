export const LOAD_SUPPRESSIONS = "LOAD_SUPPRESSIONS"
export const LOAD_SUPPRESSIONS_SUCCESS = "LOAD_SUPPRESSIONS_SUCCESS";
export const SORT_SUPPRESSIONS = "SORT_PB_SUPPRESSIONS";
export const FILTER_DATE = "FILTER_DATE";
export const FILTER_DATE_TYPE = "FILTER_DATE_TYPE";
export const RESET_STATE = "RESET_STATE";

export interface SuppressionState {
    list: any
    loading: boolean
    suppressionSortOptions: {
        field: string
        direction: boolean
    }
}

export interface Suppression {
    name?: string
}

interface LoadSuppressions {
    type: typeof LOAD_SUPPRESSIONS_SUCCESS | typeof LOAD_SUPPRESSIONS
    payload: any
}

interface SortSuppressions {
    type: typeof SORT_SUPPRESSIONS
    payload: any
}

interface FilterByDate {
    type: typeof FILTER_DATE
    payload: any
}

interface FilterByDateType {
    type: typeof FILTER_DATE_TYPE
    payload: any
}

interface ResetState {
    type: typeof RESET_STATE,
    payload: any
}

export type SuppressionsActionTypes =
    LoadSuppressions |
    SortSuppressions |
    FilterByDate |
    FilterByDateType |
    ResetState
